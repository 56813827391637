import {Link} from "@aws-amplify/ui-react";
import React from "react";

const getLink = function (amount, name, link) {
    const label = amount ? amount + " x " + name : name;

    return <Link
        href={link}
        color="#F4D03F"
        isExternal={true}
    >
        {label}
    </Link>
}

export const zanRune = function (amount) {
    return getLink(amount, "Zan", "https://www.wowhead.com/diablo-4-ptr/item/zan-1968550");
}

export const zecRune = function (amount) {
    return getLink(amount, "Zec", "https://www.wowhead.com/diablo-4-ptr/item/zec-1968672");
}