import {Link} from "@aws-amplify/ui-react";
import React from "react";

const getLink = function (amount, name, link) {
    const label = amount ? amount + " x " + name : name;

    return <Link
        href={link}
        color="#F4D03F"
        isExternal={true}
    >
        {label}
    </Link>
}

export const grandRuby = function (amount) {
    return getLink(amount, "Grand Ruby", "https://www.wowhead.com/diablo-4-ptr/item/grand-ruby-315738");
}





