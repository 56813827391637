//created 20241011
export const AFFIXES = [
            "+X_0 Energy when a Stun Grenade Explodes",
            "+X_0 Essence On Hit",
            "+X_0 Essence per Enemy Drained by Blood Surge",
            "+X_0 Maximum Resolve Stacks",
            "+X_0 to Adrenaline Rush",
            "+X_0 to All Passives",
            "+X_0 to Chilling Weight",
            "+X_0 to Cold Front",
            "+X_0 to Concussion",
            "+X_0 to Crippling Darkness",
            "+X_0 to Darkness Skills",
            "+X_0 to Drain Vitality",
            "+X_0 to Endurance",
            "+X_0 to Iron Fur",
            "+X_0 to Shadow Crash",
            "+X_0 to Skeletal Warrior Mastery, Skeletal Mage Mastery, and Golem Mastery",
            "+X_0 to Stand Alone",
            "+X_0 to Trick Attacks",
            "+X_0 to Vigorous",
            "+X_0 Mount Armor",
            "+X_0 Resource On Hit",
            "+X_0% Maximum Poison Resistance",
            "+10.0% Cold Resistance +10.0% Resistance to All Elements",
            "+10.0% Fire Resistance +10.0% Resistance to All Elements",
            "+10.0% Lightning Resistance +10.0% Resistance to All Elements",
            "+10.0% Poison Resistance +10.0% Resistance to All Elements",
            "+10.0% Shadow Resistance +10.0% Resistance to All Elements",
            "+100.0% Chance When Struck to Fortify for 1,000 Life",
            "+100.0% Damage +100.0% Critical Strike Damage +100.0% Overpower Damage +100.0% Damage Over Time",
            "+X_0 to Grim Harvest",
            "+X_0 to Potent Warding",
            "+X_0 to Soulrift",
            "+X_0% Damage to Angels and Demons",
            "+X_0% Resource Generation with Polearms",
            "+X_0 to All Skills",
            "+X_0% Critical Strike, Vulnerable, and Overpower Damage",
            "+X_0% Main Hand Weapon Damage",
            "+X_0 All Stats per Ferocity or Resolve Stack",
            "+X_0 All Stats",
            "+X_0 Armor",
            "+X_0 Dexterity",
            "+X_0 Energy On Kill",
            "+X_0 Energy per Second",
            "+X_0 Essence On Kill",
            "+X_0 Essence per Second",
            "+X_0 Fury On Kill",
            "+X_0 Fury per Second",
            "+X_0 Intelligence",
            "+X_0 Life On Hit",
            "+X_0 Life On Kill",
            "+X_0 Life per 5 Seconds",
            "+X_0 Mana On Kill",
            "+X_0 Mana per Second",
            "+X_0 Mana when a Fireball Explodes",
            "+X_0 Maximum Energy",
            "+X_0 Maximum Essence",
            "+X_0 Maximum Evade Charge",
            "+X_0 Maximum Evade Charges",
            "+X_0 Maximum Fury",
            "+X_0 Maximum Life",
            "+X_0 Maximum Mana",
            "+X_0 Maximum Resource",
            "+X_0 Maximum Spirit",
            "+X_0 Maximum Vigor",
            "+X_0 Potion Capacity",
            "+X_0 Quill Volley Extra Projectiles",
            "+X_0 Razor Wings Charges",
            "+X_0 Resolve Generated",
            "+X_0 Spirit On Kill",
            "+X_0 Spirit per Second",
            "+X_0 Strength",
            "+X_0 Thorns",
            "+X_0 Vigor On Kill",
            "+X_0 Vigor per Second",
            "+X_0 Willpower",
            "+X_0 to Abundance",
            "+X_0 to Acceleration",
            "+X_0 to Aftermath",
            "+X_0 to Aggressive Resistance",
            "+X_0 to Agile",
            "+X_0 to Agility Skills",
            "+X_0 to Alchemical Advantage",
            "+X_0 to Alchemist's Fortune",
            "+X_0 to Amplify Damage",
            "+X_0 to Apex",
            "+X_0 to Arc Lash",
            "+X_0 to Armored Hide",
            "+X_0 to Auspicious",
            "+X_0 to Ball Lightning",
            "+X_0 to Barrage",
            "+X_0 to Bash",
            "+X_0 to Basic Skills",
            "+X_0 to Bastion",
            "+X_0 to Blade Shift",
            "+X_0 to Blight",
            "+X_0 to Blizzard",
            "+X_0 to Blood Howl",
            "+X_0 to Blood Lance",
            "+X_0 to Blood Mist",
            "+X_0 to Blood Surge",
            "+X_0 to Bone Prison",
            "+X_0 to Bone Spear",
            "+X_0 to Bone Spirit",
            "+X_0 to Bone Splinters",
            "+X_0 to Boulder",
            "+X_0 to Brawling Skills",
            "+X_0 to Brilliance",
            "+X_0 to Brute Force",
            "+X_0 to Call of the Wild",
            "+X_0 to Caltrops",
            "+X_0 to Chain Lightning",
            "+X_0 to Challenging Shout",
            "+X_0 to Charge",
            "+X_0 to Charged Bolts",
            "+X_0 to Circle of Life",
            "+X_0 to Clarity",
            "+X_0 to Claw",
            "+X_0 to Coalesced Blood",
            "+X_0 to Cold Imbuement",
            "+X_0 to Companion Skills",
            "+X_0 to Compound Fracture",
            "+X_0 to Concealment",
            "+X_0 to Concussive Stomp",
            "+X_0 to Conjuration Mastery",
            "+X_0 to Conjuration Skills",
            "+X_0 to Consuming Shadows",
            "+X_0 to Core Skills",
            "+X_0 to Corpse Explosion",
            "+X_0 to Corpse Skills",
            "+X_0 to Corpse Tendrils",
            "+X_0 to Counterattack",
            "+X_0 to Counteroffensive",
            "+X_0 to Crippling Flames",
            "+X_0 to Crushing Earth",
            "+X_0 to Crushing Hand",
            "+X_0 to Curse Skills",
            "+X_0 to Cut to the Bone",
            "+X_0 to Cyclone Armor",
            "+X_0 to Dance of Knives",
            "+X_0 to Dark Shroud",
            "+X_0 to Dash",
            "+X_0 to Deadly Venom",
            "+X_0 to Death Blow",
            "+X_0 to Death's Approach",
            "+X_0 to Death's Embrace",
            "+X_0 to Debilitating Roar",
            "+X_0 to Decompose",
            "+X_0 to Decrepify",
            "+X_0 to Defensive Posture",
            "+X_0 to Defensive Skills",
            "+X_0 to Defensive Stance",
            "+X_0 to Defiance",
            "+X_0 to Devouring Blaze",
            "+X_0 to Digitigrade Gait",
            "+X_0 to Dominant",
            "+X_0 to Double Swing",
            "+X_0 to Duelist",
            "+X_0 to Earth Spike",
            "+X_0 to Earthen Bulwark",
            "+X_0 to Elemental Dominance",
            "+X_0 to Endless Pyre",
            "+X_0 to Endless Tempest",
            "+X_0 to Envenom",
            "+X_0 to Evulsion",
            "+X_0 to Exploit",
            "+X_0 to Expose Vulnerability",
            "+X_0 to Familiar",
            "+X_0 to Fiery Surge",
            "+X_0 to Fire Bolt",
            "+X_0 to Fireball",
            "+X_0 to Firewall",
            "+X_0 to Flame Shield",
            "+X_0 to Flay",
            "+X_0 to Flurry",
            "+X_0 to Focus Skills",
            "+X_0 to Follow Through",
            "+X_0 to Forceful Arrow",
            "+X_0 to Frenzy",
            "+X_0 to Frigid Breeze",
            "+X_0 to Frigid Finesse",
            "+X_0 to Frost Bolt",
            "+X_0 to Frost Nova",
            "+X_0 to Frozen Orb",
            "+X_0 to Fueled by Death",
            "+X_0 to Fueled",
            "+X_0 to Furnace",
            "+X_0 to Glass Cannon",
            "+X_0 to Gloom",
            "+X_0 to Golem Mastery",
            "+X_0 to Ground Stomp",
            "+X_0 to Guttural Yell",
            "+X_0 to Hammer of the Ancients",
            "+X_0 to Haste",
            "+X_0 to Heartseeker",
            "+X_0 to Heavy Handed",
            "+X_0 to Heightened Senses",
            "+X_0 to Hellbent Commander",
            "+X_0 to Hemorrhage",
            "+X_0 to Hewed Flesh",
            "+X_0 to Hoarfrost",
            "+X_0 to Hurricane",
            "+X_0 to Hydra",
            "+X_0 to Ice Armor",
            "+X_0 to Ice Blades",
            "+X_0 to Ice Shards",
            "+X_0 to Icy Touch",
            "+X_0 to Imbuement Skills",
            "+X_0 to Imperfectly Balanced",
            "+X_0 to Impetus",
            "+X_0 to Imposing Presence",
            "+X_0 to Incinerate",
            "+X_0 to Inner Flames",
            "+X_0 to Innervation",
            "+X_0 to Invigorating Conduit",
            "+X_0 to Invigorating Fury",
            "+X_0 to Invigorating Strike",
            "+X_0 to Iron Maiden",
            "+X_0 to Iron Skin",
            "+X_0 to Kick",
            "+X_0 to Landslide",
            "+X_0 to Leap",
            "+X_0 to Lightning Spear",
            "+X_0 to Lightning Storm",
            "+X_0 to Lunging Strike",
            "+X_0 to Macabre Skills",
            "+X_0 to Malice",
            "+X_0 to Martial Vigor",
            "+X_0 to Mastery Skills",
            "+X_0 to Maul",
            "+X_0 to Memento Mori",
            "+X_0 to Mending",
            "+X_0 to Meteor",
            "+X_0 to Mighty Throw",
            "+X_0 to Natural Disaster",
            "+X_0 to Nature's Reach",
            "+X_0 to Nature's Resolve",
            "+X_0 to No Mercy",
            "+X_0 to Nourishment",
            "+X_0 to Oppressive",
            "+X_0 to Outburst",
            "+X_0 to Patient Guard",
            "+X_0 to Payback",
            "+X_0 to Penetrating Shot",
            "+X_0 to Permafrost",
            "+X_0 to Pit Fighter",
            "+X_0 to Poison Creeper",
            "+X_0 to Poison Imbuement",
            "+X_0 to Poison Trap",
            "+X_0 to Potency Skills",
            "+X_0 to Potent",
            "+X_0 to Precision Imbuement",
            "+X_0 to Predatory Instinct",
            "+X_0 to Pressure Point",
            "+X_0 to Prolific Fury",
            "+X_0 to Pulverize",
            "+X_0 to Puncture",
            "+X_0 to Quickshift",
            "+X_0 to Quill Volley",
            "+X_0 to Rabies",
            "+X_0 to Raid Leader",
            "+X_0 to Rake",
            "+X_0 to Rallying Cry",
            "+X_0 to Rapid Fire",
            "+X_0 to Rapid Ossification",
            "+X_0 to Ravager",
            "+X_0 to Ravenous",
            "+X_0 to Ravens",
            "+X_0 to Razor Wings",
            "+X_0 to Reap",
            "+X_0 to Reaper's Pursuit",
            "+X_0 to Rend",
            "+X_0 to Resilient",
            "+X_0 to Resolution",
            "+X_0 to Resonance",
            "+X_0 to Rock Splitter",
            "+X_0 to Rupture",
            "+X_0 to Rushing Claw",
            "+X_0 to Scourge",
            "+X_0 to Second Wind",
            "+X_0 to Sever",
            "+X_0 to Shadow Imbuement",
            "+X_0 to Shadow Step",
            "+X_0 to Shocking Impact",
            "+X_0 to Shred",
            "+X_0 to Siphoning Strikes",
            "+X_0 to Skeletal Mage Mastery",
            "+X_0 to Skeletal Warrior Mastery",
            "+X_0 to Slashing Skills",
            "+X_0 to Slaying Strike",
            "+X_0 to Smoke Grenade",
            "+X_0 to Snap Freeze",
            "+X_0 to Soar",
            "+X_0 to Spark",
            "+X_0 to Spiked Armor",
            "+X_0 to Steel Grasp",
            "+X_0 to Stinger",
            "+X_0 to Stone Burst",
            "+X_0 to Stone Guard",
            "+X_0 to Storm Strike",
            "+X_0 to Subterfuge Skills",
            "+X_0 to Swift",
            "+X_0 to Teleport",
            "+X_0 to Terror",
            "+X_0 to Thrash",
            "+X_0 to Thunderspike",
            "+X_0 to Tides of Blood",
            "+X_0 to Tornado",
            "+X_0 to Touch of Death",
            "+X_0 to Tough as Nails",
            "+X_0 to Toxic Claws",
            "+X_0 to Toxic Skin",
            "+X_0 to Trample",
            "+X_0 to Trap Mastery",
            "+X_0 to Twisting Blades",
            "+X_0 to Unrestrained Power",
            "+X_0 to Unstable Elixirs",
            "+X_0 to Upheaval",
            "+X_0 to Velocity",
            "+X_0 to Vortex",
            "+X_0 to Wallop",
            "+X_0 to War Cry",
            "+X_0 to Warmth",
            "+X_0 to Weapon Mastery Skills",
            "+X_0 to Weapon Mastery",
            "+X_0 to Whirlwind",
            "+X_0 to Wild Impulses",
            "+X_0 to Wind Shear",
            "+X_0 to Withering Fist",
            "+X_0 to Wolves",
            "+X_0 to Wrath Skills",
            "+X_0% All Stats",
            "+X_0% Armored Hide Active Thorns Bonus",
            "+X_0% Armored Hide Resolve Generation Rate",
            "+X_0% Attack Speed for 4 Seconds After Dodging an Attack",
            "+X_0% Attack Speed while Berserking",
            "+X_0% Attack Speed",
            "+X_0% Barrier Generation",
            "+X_0% Basic Attack Speed",
            "+X_0% Basic Damage",
            "+X_0% Berserking Duration",
            "+X_0% Blight Chill Potency",
            "+X_0% Blight Size",
            "+X_0% Blizzard Damage",
            "+X_0% Blood Attack Speed",
            "+X_0% Blood Damage",
            "+X_0% Blood Lance Duration",
            "+X_0% Blood Mist Duration",
            "+X_0% Blood Orb Healing",
            "+X_0% Blood Overpower Damage",
            "+X_0% Blood Surge Nova Size",
            "+X_0% Bone Critical Strike Chance",
            "+X_0% Bone Critical Strike Damage",
            "+X_0% Bone Damage",
            "+X_0% Bone Spirit Damage",
            "+X_0% Bone Spirit Explosion Size",
            "+X_0% Bone Storm Duration",
            "+X_0% Boulder Damage",
            "+X_0% Brawling Damage",
            "+X_0% Caltrops Duration",
            "+X_0% Caltrops Size",
            "+X_0% Cataclysm Damage",
            "+X_0% Centipede Damage",
            "+X_0% Chance For Minion Attacks to Fortify You for 3% Maximum Life",
            "+X_0% Chance When Struck to Gain  Life as Barrier for  Seconds",
            "+X_0% Chance for Arc Lash to Swipe Twice",
            "+X_0% Chance for Ball Lightning Projectiles to Cast Twice",
            "+X_0% Chance for Barrage Projectiles to Cast Twice",
            "+X_0% Chance for Basic Projectiles to Cast Twice",
            "+X_0% Chance for Blight Projectiles to Cast Twice",
            "+X_0% Chance for Blizzard to Deal Double Damage",
            "+X_0% Chance for Bone Spear Projectiles to Cast Twice",
            "+X_0% Chance for Bone Splinters Projectiles to Cast Twice",
            "+X_0% Chance for Boulder Projectiles to Cast Twice",
            "+X_0% Chance for Cataclysm to Deal Double Damage",
            "+X_0% Chance for Chain Lightning to Hit Twice",
            "+X_0% Chance for Charged Bolts Projectiles to Cast Twice",
            "+X_0% Chance for Claw to Deal Double Damage",
            "+X_0% Chance for Core Projectiles to Cast Twice",
            "+X_0% Chance for Corpse Explosion to Deal Double Damage",
            "+X_0% Chance for Crushing Hand to Extra Hit",
            "+X_0% Chance for Dance of Knives to Deal Double Damage",
            "+X_0% Chance for Death Blow to Deal Double Damage",
            "+X_0% Chance for Decompose to Deal Double Damage",
            "+X_0% Chance for Dust Devils to Cast Twice",
            "+X_0% Chance for Earth Spike Projectiles to Cast Twice",
            "+X_0% Chance for Earthquake to Deal Double Damage",
            "+X_0% Chance for Fire Bolt Projectiles to Cast Twice",
            "+X_0% Chance for Fireball Projectiles to Cast Twice",
            "+X_0% Chance for Flurry to Deal Double Damage",
            "+X_0% Chance for Forceful Arrow Projectiles to Cast Twice",
            "+X_0% Chance for Frenzy to Hit Twice",
            "+X_0% Chance for Frost Bolt Projectiles to Cast Twice",
            "+X_0% Chance for Frozen Orb Projectiles to Cast Twice",
            "+X_0% Chance for Hammer of the Ancients to Deal Double Damage",
            "+X_0% Chance for Heartseeker Projectiles to Cast Twice",
            "+X_0% Chance for Hemorrhage to Form Blood Orbs",
            "+X_0% Chance for Ice Shards Projectiles to Cast Twice",
            "+X_0% Chance for Ice Spikes to Explode Twice",
            "+X_0% Chance for Incinerate to Deal Double Damage",
            "+X_0% Chance for Iron Maelstrom to Hit Twice",
            "+X_0% Chance for Kick to Deal Double Damage",
            "+X_0% Chance for Landslide Projectiles to Cast Twice",
            "+X_0% Chance for Lightning Storm to Deal Double Damage",
            "+X_0% Chance for Marksman Projectiles to Cast Twice",
            "+X_0% Chance for Meteor to Deal Double Damage",
            "+X_0% Chance for Meteorites to Deal Double Damage",
            "+X_0% Chance for Mighty Throw to Deal Double Damage",
            "+X_0% Chance for Payback to Deal Double Damage",
            "+X_0% Chance for Penetrating Shot Projectiles to Cast Twice",
            "+X_0% Chance for Projectiles to Cast Twice",
            "+X_0% Chance for Pulverize to Hit Twice",
            "+X_0% Chance for Puncture Projectiles to Cast Twice",
            "+X_0% Chance for Quill Volley Projectiles to Cast Twice",
            "+X_0% Chance for Rain of Arrows Waves to Cast Twice",
            "+X_0% Chance for Rake to Extra Hit",
            "+X_0% Chance for Rapid Fire Projectiles to Cast Twice",
            "+X_0% Chance for Ravager to Deal Double Damage",
            "+X_0% Chance for Razor Wings Projectiles to Cast Twice",
            "+X_0% Chance for Rock Splitter to Deal Double Damage",
            "+X_0% Chance for Rupture to Deal Double Damage",
            "+X_0% Chance for Sever Projectiles to Cast Twice",
            "+X_0% Chance for Sever to Deal Double Damage",
            "+X_0% Chance for Shred to Hit Twice",
            "+X_0% Chance for Skeletal Mage Attacks to Cast Twice",
            "+X_0% Chance for Skeleton Warriors to Hit Twice",
            "+X_0% Chance for Smoke Grenade to Deal Double Damage",
            "+X_0% Chance for Soar to Deal Double Damage",
            "+X_0% Chance for Spark Projectiles to Cast Twice",
            "+X_0% Chance for Steel Grasp to Deal Double Damage",
            "+X_0% Chance for Stone Burst to Cast Twice",
            "+X_0% Chance for Storm Strike to Hit Twice",
            "+X_0% Chance for The Devourer to Deal Double Damage",
            "+X_0% Chance for The Protector to Deal Double Damage",
            "+X_0% Chance for The Seeker to Deal Double Damage",
            "+X_0% Chance for Thrash to Deal Double Damage",
            "+X_0% Chance for Tornado Projectiles to Cast Twice",
            "+X_0% Chance for Touch of Death Swarms To Reinfect On Hit",
            "+X_0% Chance for Trample to Deal Double Damage",
            "+X_0% Chance for Twisting Blades to Hit Twice",
            "+X_0% Chance for Upheaval to Deal Double Damage",
            "+X_0% Chance for Vortex to Extra Hit",
            "+X_0% Chance for Wind Shear Projectiles to Cast Twice",
            "+X_0% Chance for Withering Fist to Deal Double Damage",
            "+X_0% Chance for a Second Ice Blades When Cast",
            "+X_0% Chance for a Second Lightning Spear When Cast",
            "+X_0% Charge Damage",
            "+X_0% Chill Slow Potency",
            "+X_0% Cold Damage",
            "+X_0% Cold Resistance +X_0% Fire Resistance",
            "+X_0% Cold Resistance",
            "+X_0% Companion Damage",
            "+X_0% Companion Movement Speed",
            "+X_0% Concealment Duration",
            "+X_0% Concussive Stomp Barrier Generation",
            "+X_0% Concussive Stomp Size",
            "+X_0% Conjuration Damage",
            "+X_0% Core Attack Speed",
            "+X_0% Core Damage",
            "+X_0% Corpse Explosion Damage",
            "+X_0% Corpse Explosion Size",
            "+X_0% Corpse Tendrils Damage",
            "+X_0% Corpse Tendrils Duration",
            "+X_0% Corpse Tendrils Size",
            "+X_0% Crackling Energy Damage",
            "+X_0% Crafting Material Drop Rate",
            "+X_0% Critical Strike Chance Against Chilled Enemies",
            "+X_0% Critical Strike Chance Against Close Enemies",
            "+X_0% Critical Strike Chance Against Crowd Controlled Enemies",
            "+X_0% Critical Strike Chance Against Feared Enemies",
            "+X_0% Critical Strike Chance Against Injured Enemies",
            "+X_0% Critical Strike Chance Against Stunned Enemies",
            "+X_0% Critical Strike Chance to each Enhanced Rapid Fire Bonus",
            "+X_0% Critical Strike Chance",
            "+X_0% Critical Strike Damage",
            "+X_0% Crowd Control Duration",
            "+X_0% Crushing Hand Size",
            "+X_0% Curse Duration",
            "+X_0% Curse Size",
            "+X_0% Cutthroat Attack Speed",
            "+X_0% Cutthroat Critical Strike Chance",
            "+X_0% Cutthroat Critical Strike Damage",
            "+X_0% Cutthroat Damage",
            "+X_0% Cyclone Armor Active Size",
            "+X_0% Cyclone Armor Damage",
            "+X_0% Damage Over Time",
            "+X_0% Damage for 4 Seconds After Dodging an Attack",
            "+X_0% Damage for 4 Seconds After Killing an Elite",
            "+X_0% Damage for 4 Seconds After Picking Up a Blood Orb",
            "+X_0% Damage on Next Attack After Entering Stealth",
            "+X_0% Damage per Combo Point Spent",
            "+X_0% Damage per Dark Shroud Shadow",
            "+X_0% Damage to Bleeding Enemies",
            "+X_0% Damage to Burning Enemies",
            "+X_0% Damage to Chilled Enemies",
            "+X_0% Damage to Close Enemies",
            "+X_0% Damage to Crowd Controlled Enemies",
            "+X_0% Damage to Dazed Enemies",
            "+X_0% Damage to Distant Enemies",
            "+X_0% Damage to Elites",
            "+X_0% Damage to Enemies Affected by Curse Skills",
            "+X_0% Damage to Enemies Affected by Trap Skills",
            "+X_0% Damage to Frozen Enemies",
            "+X_0% Damage to Healthy Enemies",
            "+X_0% Damage to Immobilized Enemies",
            "+X_0% Damage to Injured Enemies",
            "+X_0% Damage to Knocked-Down Enemies",
            "+X_0% Damage to Poisoned Enemies",
            "+X_0% Damage to Shadow Damage Over Time-Affected Enemies",
            "+X_0% Damage to Slowed Enemies",
            "+X_0% Damage to Stunned Enemies",
            "+X_0% Damage when Swapping Weapons",
            "+X_0% Damage while Berserking",
            "+X_0% Damage while Fortified",
            "+X_0% Damage while Healthy",
            "+X_0% Damage while Shapeshifted",
            "+X_0% Damage while War Cry is Active",
            "+X_0% Damage while Wrath of the Berserker is Active",
            "+X_0% Damage while in Human Form",
            "+X_0% Damage with Dual-Wielded Weapons",
            "+X_0% Damage with Ranged Weapons",
            "+X_0% Damage with Two-Handed Bludgeoning Weapons",
            "+X_0% Damage with Two-Handed Slashing Weapons",
            "+X_0% Damage",
            "+X_0% Darkness Damage",
            "+X_0% Dash Damage",
            "+X_0% Death Blow Damage",
            "+X_0% Death Blow Size",
            "+X_0% Debilitating Roar Duration",
            "+X_0% Decompose Explosion Size",
            "+X_0% Decrepify Size",
            "+X_0% Desecrated Ground Damage",
            "+X_0% Dexterity",
            "+X_0% Dodge Chance",
            "+X_0% Double Swing Size",
            "+X_0% Dust Devil Damage",
            "+X_0% Dust Devil Duration",
            "+X_0% Dust Devil Size",
            "+X_0% Eagle Damage",
            "+X_0% Earth Attack Speed",
            "+X_0% Earth Critical Strike Chance",
            "+X_0% Earth Critical Strike Damage",
            "+X_0% Earth Damage",
            "+X_0% Earth Lucky Hit Chance",
            "+X_0% Earth Overpower Damage",
            "+X_0% Earthen Bulwark Duration",
            "+X_0% Earthquake Damage",
            "+X_0% Earthquake Duration",
            "+X_0% Earthquake Size",
            "+X_0% Enhanced Rupture Explosion Size",
            "+X_0% Familiar Damage",
            "+X_0% Familiar Duration",
            "+X_0% Familiar Explosion Size",
            "+X_0% Fire Damage Over Time",
            "+X_0% Fire Damage",
            "+X_0% Fire Lucky Hit Chance",
            "+X_0% Fire Resistance",
            "+X_0% Fire and Cold Damage",
            "+X_0% Fireball Attack Speed",
            "+X_0% Fireball Projectile Speed",
            "+X_0% Firewall Size",
            "+X_0% Flame Shield Duration",
            "+X_0% Flay Duration",
            "+X_0% Flurry Healing",
            "+X_0% Flurry Size",
            "+X_0% Focus Damage",
            "+X_0% Fortify Generation",
            "+X_0% Freeze Duration",
            "+X_0% Frenzy Duration",
            "+X_0% Frost Damage",
            "+X_0% Frost Nova Size",
            "+X_0% Golems Damage",
            "+X_0% Gorilla Damage",
            "+X_0% Grenade Damage",
            "+X_0% Grizzly Rage Duration",
            "+X_0% Ground Stomp Damage",
            "+X_0% Ground Stomp Size",
            "+X_0% Hammer of the Ancients Size",
            "+X_0% Healing Received",
            "+X_0% Heartseeker Duration",
            "+X_0% Hemorrhage Explosion Size",
            "+X_0% Hurricane Damage",
            "+X_0% Hurricane Duration",
            "+X_0% Hurricane Size",
            "+X_0% Hydra Lucky Hit Chance",
            "+X_0% Ice Blades Damage",
            "+X_0% Ice Blades Lucky Hit Chance",
            "+X_0% Ice Spike Damage",
            "+X_0% Imbued Critical Strike Damage",
            "+X_0% Imbued Damage",
            "+X_0% Imbuement Damage",
            "+X_0% Immobilize Duration",
            "+X_0% Incinerate Size",
            "+X_0% Inner Sight Duration",
            "+X_0% Intelligence",
            "+X_0% Invigorating Strike Duration",
            "+X_0% Invigorating Strike Energy Regeneration",
            "+X_0% Iron Maiden Damage",
            "+X_0% Iron Maiden Size",
            "+X_0% Jaguar Damage",
            "+X_0% Kick Damage",
            "+X_0% Kick Vulnerable Duration",
            "+X_0% Lacerate Damage",
            "+X_0% Lacerate Duration",
            "+X_0% Leap Damage",
            "+X_0% Leap Slam Size",
            "+X_0% Lightning Bolt Damage",
            "+X_0% Lightning Critical Strike Damage",
            "+X_0% Lightning Damage",
            "+X_0% Lightning Resistance +X_0% Cold Resistance",
            "+X_0% Lightning Resistance +X_0% Fire Resistance",
            "+X_0% Lightning Resistance",
            "+X_0% Lightning Spear Damage",
            "+X_0% Lightning Spear Lucky Hit Chance",
            "+X_0% Lightning Storm Duration",
            "+X_0% Lucky Hit Chance while You Have a Barrier",
            "+X_0% Lucky Hit Chance",
            "+X_0% Lunging Strike Healing",
            "+X_0% Lunging Strike Range",
            "+X_0% Macabre Damage",
            "+X_0% Marksman Attack Speed per Precison Stack",
            "+X_0% Marksman Critical Strike Chance",
            "+X_0% Marksman Critical Strike Damage",
            "+X_0% Marksman Damage",
            "+X_0% Mastery Damage",
            "+X_0% Maul Size",
            "+X_0% Maximum Life while Dark Shroud is Active",
            "+X_0% Maximum Resistance to All Elements",
            "+X_0% Maximum Shadow Resistance",
            "+X_0% Meteor Size",
            "+X_0% Mighty Throw Pulse Size",
            "+X_0% Minion Attack Speed",
            "+X_0% Mobility Damage",
            "+X_0% Movement Speed during Blood Mist",
            "+X_0% Movement Speed for 4 Seconds After Killing an Elite",
            "+X_0% Movement Speed for 4 Seconds After Picking Up Crackling Energy",
            "+X_0% Movement Speed for 7 Seconds After Killing an Elite",
            "+X_0% Movement Speed from Blade Shift",
            "+X_0% Movement Speed per Dark Shroud Shadow",
            "+X_0% Movement Speed per Walking Arsenal Bonus",
            "+X_0% Movement Speed while Berserking",
            "+X_0% Movement Speed while Cataclysm is Active",
            "+X_0% Movement Speed while Hurricane is Active",
            "+X_0% Movement Speed while the Inner Sight Gauge is Full",
            "+X_0% Movement Speed",
            "+X_0% Mystic Circle Potency",
            "+X_0% Non-Physical Damage",
            "+X_0% Overpower Chance",
            "+X_0% Overpower Damage with Two-Handed Bludgeoning Weapons",
            "+X_0% Overpower Damage",
            "+X_0% Pestilent Swarm Damage",
            "+X_0% Petrify Duration",
            "+X_0% Physical Critical Strike Chance Against Elites",
            "+X_0% Physical Damage Over Time",
            "+X_0% Physical Damage",
            "+X_0% Poison Creeper Damage",
            "+X_0% Poison Creeper Duration",
            "+X_0% Poison Damage",
            "+X_0% Poison Resistance +X_0% Cold Resistance",
            "+X_0% Poison Resistance +X_0% Fire Resistance",
            "+X_0% Poison Resistance +X_0% Lightning Resistance",
            "+X_0% Poison Resistance",
            "+X_0% Poison Trap Duration",
            "+X_0% Poison Trap Size",
            "+X_0% Potency Damage",
            "+X_0% Potion Drop Rate",
            "+X_0% Potion Healing",
            "+X_0% Primary Centipede Spirit Hall Potency",
            "+X_0% Primary Eagle Spirit Hall Potency",
            "+X_0% Primary Gorilla Spirit Hall Potency",
            "+X_0% Primary Jaguar Spirit Hall Potency",
            "+X_0% Pulverize Size",
            "+X_0% Pyromancy Attack Speed",
            "+X_0% Pyromancy Critical Strike Damage",
            "+X_0% Pyromancy Damage",
            "+X_0% Rabies Damage",
            "+X_0% Rabies Duration",
            "+X_0% Rain of Arrows Damage",
            "+X_0% Rake Size",
            "+X_0% Ravager On Kill Duration Extension",
            "+X_0% Ravens Active Size",
            "+X_0% Ravens Attack Speed",
            "+X_0% Ravens Damage",
            "+X_0% Reap Duration",
            "+X_0% Rend Size",
            "+X_0% Resistance to All Elements",
            "+X_0% Resource Generation while Wielding a Scythe",
            "+X_0% Resource Generation while Wielding a Shield",
            "+X_0% Resource Generation with Dual-Wielded Weapons",
            "+X_0% Resource Generation with Two-Handed Bludgeoning Weapons",
            "+X_0% Resource Generation with Two-Handed Slashing Weapons",
            "+X_0% Rock Splitter Block Chance Bonus",
            "+X_0% Rock Splitter Size",
            "+X_0% Rupture Damage",
            "+X_0% Scourge Poisoning Duration",
            "+X_0% Sever Size",
            "+X_0% Shadow Clone Damage",
            "+X_0% Shadow Clone Duration",
            "+X_0% Shadow Damage Over Time",
            "+X_0% Shadow Damage",
            "+X_0% Shadow Imbuement Size",
            "+X_0% Shadow Lucky Hit Chance",
            "+X_0% Shadow Resistance +X_0% Cold Resistance",
            "+X_0% Shadow Resistance +X_0% Fire Resistance",
            "+X_0% Shadow Resistance +X_0% Lightning Resistance",
            "+X_0% Shadow Resistance +X_0% Poison Resistance",
            "+X_0% Shadow Resistance",
            "+X_0% Shadow Step Damage",
            "+X_0% Shadow Step Duration",
            "+X_0% Shapeshifting Attack Speed",
            "+X_0% Shock Critical Strike Chance",
            "+X_0% Shock Critical Strike Damage",
            "+X_0% Shock Damage",
            "+X_0% Shred Critical Strike Chance",
            "+X_0% Shrine Buff Duration",
            "+X_0% Skeletal Mages Damage",
            "+X_0% Skeleton Priest Effect Duration",
            "+X_0% Smoke Grenade Damage",
            "+X_0% Smoke Grenade Duration",
            "+X_0% Smoke Grenade Size",
            "+X_0% Soar Size",
            "+X_0% Soulrift Duration",
            "+X_0% Steel Grasp Damage",
            "+X_0% Steel Grasp Duration",
            "+X_0% Stinger Size",
            "+X_0% Storm Critical Strike Chance",
            "+X_0% Storm Damage",
            "+X_0% Storm Feather Potency",
            "+X_0% Strength",
            "+X_0% Stun Duration",
            "+X_0% Stun Grenade Damage",
            "+X_0% Stun Grenade Size",
            "+X_0% Summoning Damage",
            "+X_0% Teleport Damage",
            "+X_0% Teleport Nova Size",
            "+X_0% The Protector Barrier Generation",
            "+X_0% Thorns while Fortified",
            "+X_0% Thunderspike Size",
            "+X_0% Total Armor while in Werebear Form",
            "+X_0% Total Armor while in Werewolf Form",
            "+X_0% Total Armor",
            "+X_0% Touch of Death Healing Bonus",
            "+X_0% Touch of Death Swarm Duration",
            "+X_0% Trample Damage",
            "+X_0% Trap Damage",
            "+X_0% Ultimate Damage",
            "+X_0% Upheaval Size",
            "+X_0% Vortex Size",
            "+X_0% Vulnerable Damage",
            "+X_0% Weapon Mastery Attack Speed",
            "+X_0% Weapon Mastery Damage",
            "+X_0% Werebear Damage",
            "+X_0% Werebear Overpower Damage",
            "+X_0% Werewolf Attack Speed",
            "+X_0% Werewolf Critical Strike Chance",
            "+X_0% Werewolf Critical Strike Damage",
            "+X_0% Werewolf Damage",
            "+X_0% Whirlwind Size",
            "+X_0% Willpower",
            "+X_0% Withering Fist Size",
            "+X_0% Wolves Attack Speed",
            "+X_0% Wolves Damage",
            "+X_0% to Lupine Ferocity's Damage Bonus",
            "+X_0% to Prime Bone Storm's Damage Reduction",
            "+X_0% to Shatter's Damage Echo",
            "+X_0% to the Pack Leader Spirit Boon's Lucky Hit Chance",
            "-",
            "15.0%[x] Damage",
            "20% Block Chance 41% Blocked Damage Reduction",
            "X_0% Block Chance",
            "A Pestilent Swarm spawns from you every 10 seconds, dealing X_0 Poison damage per hit. Your Pestilent Swarms now orbit around you and generate 1 Vigor per hit.",
            "A dark aura surrounds you, inflicting Decrepify and Iron Maiden on enemies who touch it. Curses inflicted this way spread to surrounding targets every X_0 seconds and last 4 seconds outside of the aura.",
            "A hail of Meteorites falls during Inferno, dealing X_0 Fire damage on impact. Your Meteorites Immobilize enemies for 3 seconds.",
            "According to your secondary Spirit Hall choice, your Skills are all additionally Jaguar, Eagle, Gorilla, or Centipede Skills.Your Skills deal X_0%[x] increased damage per Spirit type they have.",
            "After 30 seconds of Night's Grasp, gain Dawn's Haste, increasing your Attack Speed by X_0% and Movement Speed by 20% for 12 seconds. While empowered by the Midwinter Ward, killing an enemy reduces Night's Grasp's duration by 1 second.",
            "After Charged Bolts hits enemies 50 times, your next 3 casts of Charged Bolts becomes waves that pierce and deal X_0 Critical Shock Damage.",
            "After Immobilize or Stun wears off, enemies are Slowed by X_0% for 4 seconds.",
            "After attacking enemies with a Basic Skill, increase the damage of your next Core Skill cast within 5 seconds by X_0%[x], up to 30%[x].",
            "After being damaged for at least 20% of your Maximum Life at once, you become Immune for X_0 seconds. Can only occur once every 20 seconds.",
            "After casting 5 Basic Skills, one of your active Cooldowns is reduced by X_0 seconds.",
            "After casting Double Swing 4 times, your next Double Swing will hit 2 additional times, each dealing X_0%[x] increased damage.",
            "After casting your Ultimate Skill, gain X_0 of your maximum Primary Resource over 10 seconds. While active, every 10 Primary Resource you spend reduces your active Cooldowns by 1 seconds.",
            "After channeling Incinerate for 3 seconds it deals X_0 Critical Fire damage per second for 5 seconds. Casting Incinerate refreshes and maintains this bonus.",
            "After generating 100 Fury your next direct damage creates 2 Dust Devils that deal X_0 damage to enemies along their path.",
            "After hitting 15 enemies with your Shapeshifting Skills, your next Werebear Skill will deal X_0%[x] more damage and Stun enemies for 3 seconds.",
            "After killing an enemy with Shred, you gain 20 Spirit. Your Werewolf Skills deal X_0%[x] increased damage when cast above 50 Spirit.",
            "After spending 100 Fury within 3 seconds, your next cast of Hammer of the Ancients, Upheaval, or Death Blow within 5 seconds is a guaranteed Critical Strike and deals X_0%[x] bonus Critical Strike Damage.",
            "After spending 100 Fury, your next Weapon Mastery Skill within 5 seconds deals X_0%[x] increased damage.",
            "After spending X_0 Fury within 2 seconds, your next Non-Basic Skill's Critical Strike Chance is increased by X_1%[+].",
            "After spending X_0 Mana your next Firewall is free to cast and will destroy incoming Small Missiles.",
            "After spending X_0 of your Primary Resource, your next attack Stuns enemies hit for 5 seconds.",
            "After swapping weapons 5 times, gain X_0 Fortify.",
            "After swapping weapons 8 times, your next non-Basic Skill will Overpower and deal X_0%[x] increased Overpower damage.",
            "After taking Elemental Damage, gain X_0%[x] Damage Reduction for 9 seconds.",
            "After using Teleport, Close enemies are Pulled to you and Stunned for X_0 seconds, but Teleport's Cooldown is increased by 20%[x].",
            "After using a combat dismount skill, you gain 40% movement speed bonus for 10 seconds.",
            "After using a combat dismount skill, you gain a shield equal to 20% of your Maximum Life.",
            "After you Evade, your next Core Skill dashes to your target and does X_0%[x] increased damage.",
            "After you spend 275 of your Primary Resource, your next Core Skill is guaranteed to Overpower. Your Critical Strikes that Overpower deal X_0%[x] increased damage.",
            "Ancients you summon are empowered.Korlic creates an Earthquake that deals X_0 Physical damage over 4 seconds when he leaps.Talic leaves behind Dust Devils that deal X_1 damage while he whirlwinds.Mawdac ignites the ground Burning enemies for an additional X_0 damage over 4 seconds when he upheaves the ground.",
            "Attacking resets evade cooldown.",
            "Attacks Reduce Evade's Cooldown by X_0 Seconds",
            "Attacks against Frozen targets increase your critical strike chance by X_0%. This bonus resets upon getting a critical strike.",
            "Ball Lightning orbits around you and deal X_0%[x] increased damage. You may have up to 10 Ball Lightnings and casting more increases the damage of the existing ones by 10% each.",
            "Ball Lightning pulls enemies with it as it travels.",
            "Barrage arrows now pierce through 1 enemy.Barrage has X_0%[+] increased Attack Speed.",
            "Bash Cleaves for +X_0% Damage",
            "Basic Skills deal X_0%[x] increased damage but additionally cost 25 Primary Resource.",
            "Basic Skills gain X_0%[+] Attack Speed.",
            "Basic Skills grant 20.0% Damage Reduction for X_0 seconds.",
            "Becoming Injured while Crowd Controlled grants you Unstoppable for 4 seconds. This effect has a X_0 second Cooldown.",
            "Bleeding enemy you kill explodes, dealing 10% of their max life to nearby enemies.",
            "Blight also shoots 4 smaller projectiles that pierce enemies and deal X_0 Shadow damage over 3 seconds.",
            "Blight's defiled area, when spawned, pulls in enemies around the affected area.",
            "Blood Howl increases Critical Strike Chance by X_0%[+]. In addition, Blood Howl also affects Nearby Players for 3 seconds.",
            "Blood Lance deals X_0%[x] increased damage to its primary target per lanced enemy.",
            "Blood Mist leaves a trail of Desecrated Ground that lasts for 4 seconds and deals X_0 Shadow damage per second. Gain 20%[+] Movement Speed while Blood Mist is active.",
            "Blood Mist triggers Corpse Explosion on surrounding Corpses. When Blood Mist detonates a Corpse, its Cooldown is reduced by X_0 seconds.",
            "Blood Orbs Restore +X_0 Essence",
            "Blood Orbs grant X_0 Essence.",
            "Blood Orbs reduce your Ultimate Cooldown by X_0 seconds.",
            "Blood Surge Drains +2 Times from Elites",
            "Blood Surge casts a mini nova on your Minions, dealing X_0 damage. Damage is increased by 10% per target drained by the initial cast, up to 50%.",
            "Blood Surge's nova echoes again after a short delay, dealing X_0%[x] less damage.",
            "Blood Wave fires two additional waves, each dealing X_0% of normal damage.",
            "Bone Prison spawns a pool of Blight that deals X_0%[x] bonus damage over 6 seconds.",
            "Bone Prison traps a larger area and fires X_0 Bone Splinters at enemies trapped within. Increase your Maximum Essence by 2 for 10 seconds each time these Bone Splinters hit an enemy.",
            "Bone Spear leaves behind echoes that explode for X_0 Physical damage, increased by 5%[x] for every 30% Critical Strike Damage you have.Current Bonus: 0%[x]",
            "Bone Spear's primary attack makes enemies hit beyond the first Vulnerable for 3 seconds. Bone Shards from Bone Spear deal X_0% bonus damage to Vulnerable enemies and pierce them.",
            "Bone Spirit's Critical Strike Chance is increased by X_0%[+].Each enemy hit by Bone Spirit increases your Maximum Essence by 2, for 15 seconds, up to 50 Essence.",
            "Bone Storm and Blood Wave are also Darkness Skills, deal Shadow damage, and gain additional effects:  Enemies damaged by Bone Storm take X_0 Shadow damage over 2 seconds.  Blood Wave creates Desecrated Ground as it travels, dealing X_1 Shadow damage over 4 seconds",
            "Bone Storm consumes up to 8 Corpses to increase its duration by up to X_0 seconds.",
            "Boulder is now also a Core Skill and costs 40 Spirit to cast dealing X_0% of normal damage.",
            "CRAAAAZY EXP GAINZ!!",
            "Caltrops and Smoke Grenade receive your Stun Grenade benefits and throw Stun Grenades that deal X_0 Physical damage and Stun enemies for 1 second.Your Stun Grenades deal X_1% increased damage.",
            "Caltrops is now also a Marksman Skill that also throws X_0 piercing daggers over its duration, each dealing 15 Shadow damage and applying Vulnerable for 3 seconds.",
            "Casted Hydras Have +X_0 Head",
            "Casting Blood Lance will consume Blood Orbs to also conjure lances from them. Each additional Blood Lance deals X_0% of normal damage and prioritizes targeting un-lanced enemies.",
            "Casting Blood Surge consumes nearby Corpses to cause mini novas, dealing X_0 damage. This damage is increased by 10%[x] for each enemy drained by your initial cast, up to 50%[x], as well as by 20%[x] for each Corpse consumed.",
            "Casting Bone Spirit also launches 18 Bone Splinters in all directions, dealing X_0%[x] increased damage and generating 6 Essence per enemy hit.",
            "Casting Boulder while Hurricane is active causes the Boulder to rotate around you instead. Boulder's damage is increased by X_0%[x] for each one currently rotating.You may have up to 10 Boulders active at a time.",
            "Casting Concealment grants 10%[+] Resistance and Maximum Resistance to All Elements for 6 seconds.Killing an Elite enemy reduces Concealment's Cooldown by X_0 seconds.",
            "Casting Dark Shroud makes you Immune for 2 seconds, but your Evade Cooldown is increased by X_0 seconds.Evading while Dark Shroud is active leaves behind an explosion that deals X_1 Shadow damage and Pulls In enemies.",
            "Casting Double Swing creates a Dust Devil that deals X_0 damage to enemies in its path.",
            "Casting Evade consumes 1 stack of Resolve to generate X_0 Vigor.Gain Armored Hide's Passive Effect.",
            "Casting Familiar now summons all three elemental variants at once.Familiar's duration is increased by X_0%[x] and its Cooldown is reduced by 2 seconds, but its maximum Charges are reduced by 1.",
            "Casting Fire Bolt through your Firewall causes it to split into 3 bolts, each dealing X_0%[x] increased damage.",
            "Casting Frozen Orb has a X_0% chance to spawn a random Conjuration when it explodes.Lucky Hit: Your Conjurations have up to a X_1% chance to launch a Frozen Orb at Nearby enemies.",
            "Casting Golem's active Skill creates a bond between you for 6 seconds. While the bond is active, both of you are Unhindered and gain X_0% Movement Speed.",
            "Casting Ice Armor makes you Unstoppable and grants 25% Damage Reduction for X_0 seconds.",
            "Casting Macabre Skills Restores +X_0 Primary Resource",
            "Casting Pyromancy, Shock, and Frost Skills conjures a matching Elemental Dagger around you that pierces through enemies dealing X_0 damage after 3 seconds. The damage increases by 100% per matching elemental skill you cast.",
            "Casting Rushing Claw extends the durations of your active Incarnate Skill effects by X_0 seconds, up to X_1 seconds.",
            "Casting Smoke Grenade increases your Critical Strike Damage by 33%[x] for X_0 seconds and leaves behind a cloud of shadows. While within the cloud, you gain Stealth once per second.",
            "Casting Steel Grasp reduces Iron Maelstrom's Cooldown by X_0 seconds. Enemies damaged by Iron Maelstrom deal X_1% less damage for 6 seconds.",
            "Casting Ultimate Skills Restores +X_0 Primary Resource",
            "Casting Wrath Skills Restores +X_0 Primary Resource",
            "Casting Wrath of the Berserker causes you to explode, dealing X_0 Fire damage. While Berserking, your direct damage dealt is converted into Fire. You deal X_1%[x] increased Fire damage.",
            "Casting a Basic Skill reduces the Mana cost of your next Core or Mastery Skill by X_0%.",
            "Casting a Companion Skill grants you X_0%[+] Movement Speed for 5 seconds, up to X_1%[+].",
            "Casting a Conjuration Skill grants you X_0%[x] Damage Reduction for 5 seconds.",
            "Casting a Core Skill additionally fires 2 instances of Fire Bolt, Frost Bolt, or Spark, matching the elements of your last 2 non-Core Skill casts. These projectiles deal X_0%[x] increased damage.",
            "Casting a Core Skill has a X_0% chance to throw Stun Grenades that deal X_1 Physical damage and Stun enemies for 1 second.Your Stun Grenades gain 5% Lucky Hit Chance.",
            "Casting a Defensive Skill deals X_0 damage to Nearby Poisoned enemies, increased by 50%[x] for every 100 Willpower you have.Current Bonus: 0%[x]",
            "Casting a Focus Skill recalls all Nearby Storm Feathers, dealing X_0 Lightning damage to enemies along their way. If there are no Nearby Storm Feathers, the cast instead spawns 10 randomly around you.",
            "Casting a Gorilla Skill increases your Weapon Damage by X_0% of your Armor for 3 seconds. Maximum 1,500 bonus Weapon Damage.",
            "Casting a Non-Basic Mobility Skill grants X_0 Resolve and causes your next Concussive Stomp to strike in a circle around you.",
            "Casting a Pyromancy, Shock, or Frost Skill increases your Mana Regeneration by X_0%[x] for 5 seconds, once per element.At maximum stacks, the total bonus is increased to X_1%[x] for 10 seconds, but all stacks expire afterwards.",
            "Casting a Pyromancy, Shock, or Frost Skill increases your damage by X_0%[x] for 8 seconds, stacking once per element. Casting again refreshes all bonuses if the previous skill was a different Element.",
            "Casting a Skill has a X_0% chance to cast a Non-Mobility, Non-Ultimate Skill that is currently on Cooldown. Can only occur once every 8 seconds.",
            "Casting a Skill while at full Life fires a Divine Barrage dealing X_0 damage.",
            "Casting a Storm Skill grants your Earth Skills X_0%[x] Critical Strike Damage for 4 seconds.Casting a Earth Skill increases the Critical Strike Chance of Storm Skills by X_1%[+] for 4 seconds.",
            "Casting a Subterfuge Skill leaves behind a Decoy Trap that continuously Taunts and lures enemies. After 2 seconds, it explodes, dealing X_0 Shadow damage. This effect is treated as a Trap Skill and can only spawn one Decoy Trap every 6 seconds.",
            "Casting an Ultimate Skill grants or amplifies its matching Primary Spirit Hall Bonus with X_0%[+] potency for 15 seconds.Your Ultimate Skills each gain an additional Skill Type: The Seeker is a Focus Skill. The Hunter is a Mobility Skill. The Devourer is a Potency Skill. The Protector is a Defensive Skill.",
            "Casts of Landslide leave behind Tectonic Spikes that deal X_0 Physical damage over 2 seconds.Spawning a Landslide pillar within Tectonic Spikes has a X_1% chance to spawn two instead.",
            "Cataclysm's lightning strikes now prioritize enemies.While Cataclysm is active, you gain unlimited Spirit and deal X_0%[x] increased damage.",
            "Chain Lightning alternates between orbiting you and seeking up to 3 enemies. When it returns, it drains 6 Mana from you for each active Chain Lightning. After draining 66 total Mana, the bolt explodes for X_0 Lightning Damage.                       Chain Lightning expires if you don't have enough Mana for it to drain.",
            "Chain Lightning has a 25% chance to deal X_0%[x] increased damage. This chance is doubled against Bosses or Crowd Controlled enemies and prefers them as targets.",
            "Chain Lightning has a X_0% chance to chain 5 additional times.",
            "Charge calls forth 4 Ancients who also Charge, dealing X_0%[x] of normal damage.",
            "Charge gains an additional Charge. Each target hit by it explodes for X_0 Fire damage to surrounding enemies.",
            "Charged Bolts pierce, but deal X_0%[x] less damage to targets hit after the first.",
            "Chilled enemies Poisoned by Poison Imbuement will be further Chilled for 20% per second. You deal X_0%[x] additional Poison damage to Frozen enemies.",
            "Claw is now also a Storm Skill and also casts Storm Strike at X_0% normal damage.",
            "Close enemies or those you deal indirect damage to are Slowed by X_0% for 3 seconds. You deal 20%[x] increased damage to Slowed enemies.",
            "Cold Imbue Lasts For +X_0 Cast",
            "Collecting Crackling Energy increases your Movement Speed by X_0%[+] for 8 seconds.",
            "Coming in contact with your Firewall grants you X_0%[+] Movement Speed for 6 seconds.",
            "Companion Skills deal an additional X_0%[x] damage per Companion you have.",
            "Conjuration Cooldowns are Reduced by +0.3 Seconds when a Frozen Orb Explodes",
            "Consuming a Corpse has a X_0% chance to spawn a Blood Orb.",
            "Consuming a Corpse increases the damage of your Core Skills by X_0%[x] for 5 seconds, up to X_1%[x].",
            "Core Skills cast at or above 100 Mana gain a X_0%[+] increased Critical Strike Chance.",
            "Core Skills that launch a projectile consume extra Mana. For every 20 extra Mana consumed, you launch an additional projectile and the damage is increased by X_0%[x].",
            "Corpse Explosion consumes up to 4 additional Corpses around the initial Corpse, gaining X_0%[x] damage and X_1% Size per additional Corpse consumed.",
            "Crackling Energy has a X_0% chance to deal 40%[x] increased damage and chain to an additional enemy.",
            "Critical Hits with Lightning Spear cause lightning to arc from it dealing X_0 damage to its target and up to 5 other enemies. This damage is increased by 100%[x] of your Critical Strike Damage Bonus.",
            "Critical Strikes and all subsequent damage within X_0 seconds is absorbed by your target. Then, the absorbed damage erupts onto surrounding enemies. Stored damage is increased by 15%[x] per second.",
            "Critical Strikes electrically charge the enemy for X_0 seconds, causing lightning to arc between them and any other charged enemies dealing X_1 Lightning damage.",
            "Critical Strikes grant X_0%[+] Movement Speed for 1 second, up to 6 seconds.",
            "Critical Strikes with Bone Skills increase your Essence Regeneration by X_0%[x] for 4 seconds.",
            "Critical Strikes with Core Skills increase your Attack Speed by X_0%[+] for 5 seconds.",
            "Critical Strikes with Shred deal X_0% of the damage dealt as Lightning damage to the target and surrounding enemies.",
            "Critical Strikes with Storm Skills that you cast charge the air around you for 3 seconds causing a Lightning Bolt to periodically hit an enemy in the area for X_0 Lightning damage. Your Lightning Bolts deal X_1%[x] increased damage.",
            "Cycle through a Malignant bonus every 20 kills.Vicious: Gain 25%[+] Attack Speed.Devious: Core and Basic Skills have a 20% chance to fully restore your Primary Resource.Brutal: Every 16 seconds, gain a Barrier absorbing X_0 damage.",
            "Cyclone Armor also provides Physical Damage Reduction. In addition, Cyclone Armor will also be applied to all Nearby Allies.",
            "Damage from Earth Skills Slow enemies hit by X_0% for 5 seconds.",
            "Damaging an Elite enemy grants you a Barrier absorbing up to X_0 damage for 10 seconds. This effect can only happen once every 20 seconds.",
            "Damaging an Elite enemy with a Basic Skill generates 4 Energy. Gain X_0 Energy after killing an Elite enemy with a Basic Skill.",
            "Damaging an enemy with Kick or Ground Stomp resets Leap's Cooldown.",
            "Damaging an enemy with a Basic Skill grants you 4% Attack Speed for 10 seconds, stacking up to 5 times. Upon reaching maximum stacks, you enter a Vampiric Bloodrage, gaining X_0%[x] Basic Skill damage and 15% Movement Speed for 10 seconds.",
            "Damaging enemies with Sever has a X_0% chance to spawn a pool of Blight under the target that deals X_1%[x] bonus damage. This effect can only happen once every 3 seconds.",
            "Damaging enemies with a Non-Basic Skill cast marks them for 3 seconds. When a Basic Skill first hits a marked enemy, the Basic Skill's damage is echoed to all marked enemies, dealing X_0%[x] increased damage.",
            "Dash spawns a Shadow Clone that also casts Dash, dealing X_0% of the Base damage.",
            "Deal X_0 Fire damage to surrounding enemies whenever a Crowd Control effect is removed from you.",
            "Deal X_0%[x] increased damage while you have a Barrier active.",
            "Deal X_0%[x] increased damage while you have no Defensive Skills on your Action Bar.",
            "Deal X_0%[x] increased damage. Triple this bonus after standing still for 3 seconds.",
            "Dealing direct damage with a Non-Agility Skill after Casting an Agility Skill grants Stealth for 2 seconds. When Stealth breaks, you gain X_0% Dodge Chance for 2 seconds.",
            "Death Blow creates a shockwave, dealing X_0% of its Base damage to enemies. Enemies who die to this effect also reset Death Blow’s Cooldown.",
            "Death Trap deals X_0%[x] increased damage and will rearm itself once after activating.",
            "Debilitating Roar is now a Werewolf Skill. In addition, Debilitating Roar will Immobilize Poisoned enemies for X_0 seconds.",
            "Decompose can chain and burst up to 2 additional targets. Explosions from Decompose deal X_0%[x] increased damage.",
            "Distant enemies have a 10% chance to be Stunned for 2 seconds when they hit you. You deal X_0%[x] increased damage to Stunned or Knocked Down enemies.",
            "Drinking a Potion Grants X_0% Movement Speed for 2 Seconds",
            "Each Chilled or Frozen enemy you Evade through grants you a Barrier that absorbs X_0 damage for 5 seconds, absorbing up to a maximum of X_1 damage.",
            "Each Minion drains X_0 Essence per second but deals X_1%[x] increased damage. With no Minions, this bonus applies to you and drains 5 Essence per second.",
            "Each cast of Blood Lance will launch an additional Blood Lance at a Nearby enemy when it first hits an enemy that is already lanced, dealing X_0% of normal damage.",
            "Each different Basic Skill you cast increases all your damage by X_0%[x] for 10 seconds.",
            "Each enemy you Evade through reduces your active Ultimate Cooldown by X_0 seconds, up to 10 seconds.",
            "Each percent of your Maximum Life you Heal beyond 100% grants you 0.5%[x] bonus Critical Strike Damage on your next Critical Strike, up to a X_0%[x] bonus.You may now drink your Healing Potion while at full Life.",
            "Each point of Fury generated while at Maximum Fury grants X_0 Fortify.",
            "Each point of Fury you generate while at Maximum Fury grants your next Core Skill within 5 seconds X_0%[x] increased damage, stacking up to 15 times.",
            "Each time Chain Lightning bounces, gain X_0 Mana.",
            "Each time a Core Skill deals direct damage to an enemy, your next Charge, Leap, or Kick deals X_0%[x] increased damage, up to X_1%[x].",
            "Each time one of your Minions damages an enemy, they gain X_0%[+] Attack Speed for 3 seconds, up to X_1%[+].",
            "Each time that Bone Storm damages an enemy, gain a Barrier equal to X_0% of your Maximum Life for 10 seconds.",
            "Each time the Shadowblight Key Passive deals damage to enemies, it increases the next Shadowblight's damage within 10 seconds by X_0%[x], stacking up to 5 times.",
            "Each time you use a Skill with a Resource Cost, gain X_0%[x] increased damage and Resource Cost is increased by 30%[+] for 4 seconds, stacking up to 5 times.",
            "Earth Skills deal X_0%[x] increased damage to Crowd Controlled enemies.",
            "Earth Spike deals X_0%[x] increased damage and launches spikes in a line.",
            "Effects that Heal you beyond 100% Life grant you a Barrier up to X_0% of your Maximum Life that lasts for 8 seconds. You may now drink your Healing Potion while at full Life.",
            "Enemies afflicted by more Damage over Time than remaining Life are Executed.",
            "Enemies damaged by Kick or Charge will explode if they are killed within the next 2 seconds, dealing X_0 damage to surrounding enemies.",
            "Enemies hit by your Stun Grenades have a chance equal to your Critical Strike Chance to be Frozen for 2 seconds. You deal X_0%[x] increased Critical Strike Damage against Frozen or Stunned enemies.",
            "Enemies that die while Frozen have a X_0% chance to unleash a Frost Nova.",
            "Enemies who move while Poisoned by you additionally take X_0% of your Thorns damage per second.",
            "Enemies you Poison have 100% Reduced Healing and your Eagle Skills gain X_0%[+] increased Critical Strike Chance against them.",
            "Enemy resource draining effects are X_0%[+] less effective. In addition, gain X_1%[x] increased Resource Generation.",
            "Evade Grants +9% Attack Speed for 3 Seconds",
            "Evade Grants +X_0% Movement Speed for 1.5 Seconds",
            "Evade Grants Unhindered for X_0 Second",
            "Evade Leaves Behind Desecrated Ground for 2 Seconds",
            "Evade can be stored up to 3 charges, 5 seconds recharge.",
            "Evading through an enemy infected by Shadow Imbuement grants Stealth for 4 seconds. Breaking Stealth Heals you for X_0% of your Maximum Life.",
            "Every 10 seconds, gain a barrier absorbing up to X_0 damage for 5 seconds.",
            "Every 20 seconds, your next Upheaval cast is guaranteed to Overpower and deals X_0%[x] increased damage. Upheaval Critical Strikes against Elites reduce this timer by 1 second.",
            "Every 30 seconds you spend in an animal form, your next Core Skill is guaranteed to Overpower and Critically Strike, and deals X_0%[x] increased damage.Casting a Shapeshifting Skill reduces this timer by 1 second, or 2 seconds if it changes you to a new animal form.",
            "Every 3rd cast of Fireball launches 2 additional projectiles and deals X_0%[x] increased damage.",
            "Every 3rd cast of Puncture is Poison Imbued with X_0% of normal potency.",
            "Every 3rd consecutive Crushing Hand smashes an additional 10 times around you dealing X_0% of its Base damage per hit.",
            "Every 5 seconds while above 60% Life, Core Skills cost X_0 Life instead of your Primary Resource. Skills that consume Life deal X_1% increased damage.",
            "Every 5 seconds, you randomly mark a Nearby enemy. Marked enemies are Vulnerable, and your attacks against them are guaranteed to Critically Strike and Overpower.Hitting a marked enemy 10 times clears the mark from them.",
            "Every 5 seconds, your next Core Skill costs X_0 Life instead of Primary Resource. Will only occur if you have at least 60% of your Maximum Life.Skills that consume Life deal 80%[+] increased damage.",
            "Every 6th attack from each Minion is empowered, exploding for X_0 Physical damage.",
            "Every 8 seconds, your Skeletal Mages cast a blizzard that deals X_0 Cold damage and continuously Chills enemies for 8% over 6 seconds.",
            "Every X_0 seconds, Distant enemies are Pulled In to you and take 10%[x] increased damage for 3 seconds.",
            "Every X_0 seconds, you zap a nearby enemy for 225 Lightning damage.This effect is treated as a Shock Skill.",
            "Every second while moving gain X_0%[+] Bonus Movement Speed for 1.5 seconds, up to X_1%[+].",
            "Every time you deal Thorns damage, you gain X_0 Vigor.Gain Toxic Skin's Passive Effect.",
            "Explosions from the Shatter Key Passive deal X_0%[x] increased damage.",
            "Explosions from the Victimize Key Passive Heal you for X_0 Life for each enemy damaged, up to a maximum of X_1 Life.",
            "Fireball now bounces as it travels, exploding each time it hits the ground, but its explosion deals X_0% of normal damage.",
            "Flame Shield grants you Unhindered for its duration. Enemies you move through while Flame Shield is active are Immobilized for X_0 seconds.",
            "Flurry damages enemies in a circle around you and deals X_0%[x] increased damage.",
            "Flurry deals X_0%[x] increased damage and has a 20% chance to Stun enemies for 3 seconds with each hit.",
            "For each unique element you deal damage with, you deal X_0%[x] increased damage for X_1 seconds.",
            "For every 10% Life you are missing, gain X_0% Damage Reduction.",
            "Frost Nova gains an additional Charge but the Cooldown per Charge is increased by X_0%.",
            "Frozen Orb deals X_0% increased damage and explodes an additional time.",
            "Gain +2 to Agility and Subterfuge Skills.",
            "Gain 1 Resolve when you Dodge an attack. Your Potency Skills consume 2 Resolve to deal X_0%[x] increased damage.",
            "Gain 1 additional Companion. In addition, your Companion Skills deal X_0%[x] bonus damage.",
            "Gain 1.0%[+] increased Armor and X_0 Thorns per 1% missing Maximum Life.",
            "Gain 10%[+] Block Chance. Blocking an attack from a Close enemy has a X_0% chance to cast a free Concussive Stomp at them.",
            "Gain 20% Damage Reduction. In addition, gain +4 Ranks to all Skills.",
            "Gain 60 Maximum Resource.When taking damage, 75% is drained as 2 Resource for every 1% of Maximum Life you would have lost.",
            "Gain Berserking for X_0 seconds after swapping weapons 8 times.",
            "Gain X_0 Fortify whenever you deal direct damage while Berserking.",
            "Gain X_0 Thorns while Berserking.",
            "Gain X_0 Thorns while Channeling and for 3 seconds afterwards.",
            "Gain X_0 of your Primary Resource for every 20% of your Life that you Heal or every 120% Life that you Overheal while at Maximum Life.",
            "Gain X_0% Damage Reduction for each Nearby Bleeding enemy up to X_1% maximum.",
            "Gain X_0% Damage Reduction while Shapeshifted into a Werewolf.",
            "Gain X_0% Evade Cooldown Reduction. You can now Evade during Blood Mist, traveling twice as far. Entering or exiting Blood Mist resets your Evade Cooldown.",
            "Gain X_0% bonus amount to your next Earthen Bulwark for each enemy you Crowd Control up to a maximum of 100%.",
            "Gain X_0%[+] Block Chance per Resolve stack.",
            "Gain X_0%[+] Movement Speed. This bonus is doubled if you haven't used a Defensive Skill in the last 8 seconds.",
            "Gain X_0%[+] increased Critical Strike Chance for 6 seconds when you cast Corpse Tendrils. You deal X_1%[x] bonus Critical Strike Damage to enemies for 6 seconds after they are damaged by Corpse Tendrils.",
            "Gain X_0%[x] increased Fury Generation and 15% reduced Fury cost while all damage bonuses from the Walking Arsenal Key Passive are active.",
            "Gain X_0%[x] increased Lucky Hit Chance while all damage bonuses from the Walking Arsenal Key Passive are active.",
            "Gain X_0%[x] increased damage to a set of damage types for 7 seconds.  This effect alternates between 2 sets: Fire, Lightning, and Physical. Cold, Poison, and Shadow.",
            "Gain a random Shrine effect for 20 seconds after killing an Elite enemy. Can only occur once every 30 seconds.",
            "Gain increased Critical Strike Damage equal to X_0%[x] of your Block Chance. Blocking doubles this bonus for 10 seconds.",
            "Gain the Flame Shield Enchantment for free. When Flame Shield activates, Meteorites fall around you dealing X_0 Fire damage.",
            "Gain the effect of the Teleport Enchantment for free. When you Evade using Teleport Enchantment you are taken to a random location.",
            "Gains 100% dodge chance during evade.",
            "Golems Inherit +X_0% of Your Thorns",
            "Grizzly Rage is now a Werewolf Skill and Shapeshifts you into a Dire Werewolf. Dire Werewolves gain X_0%[+] Movement Speed and X_1% Spirit Cost Reduction instead of Damage Reduction. In addition, kills Heal you for 10% of your Maximum Life.",
            "Ground Stomp creates an Earthquake damaging enemies for X_0 Physical damage over 4 seconds. While standing in Earthquakes, you deal X_1%[x] increased damage.",
            "Hammer of the Ancients quakes outward for X_0%[x] increased damage. This bonus is doubled against Bosses.",
            "Heal for X_0% Maximum Life when you Poison a Healthy enemy.",
            "Hits of Barrage have a X_0% chance to deal double damage and Knock Down enemies.",
            "Hits with this weapon increase your Attack Speed by X_0%[+] for 4 seconds, up to X_1%[+].",
            "Hurricane damage is increased by X_0%[x] each second while active.",
            "Ice Blades' chance to apply Vulnerable is increased by 20%[+] and the Vulnerable duration is increased by 4 seconds. You gain X_0%[x] Vulnerable Damage.",
            "Ice Shards pierce X_0 times, dealing X_1% less damage per subsequent enemy hit.",
            "Ice Spikes Freeze Enemies for X_0 Seconds",
            "If a Core Skill hits 4 or more enemies, X_0% of the Resource cost is refunded.",
            "If you have at least 2 Blue Pacts, double in size.",
            "If you haven't dealt damage in the last 2 seconds, gain Stealth and 40%[+] Movement Speed.",
            "Ignore Durability Loss",
            "Incinerate splits into 3 beams, each dealing X_0%[x] of normal damage.",
            "Incoming damage has a X_0% chance of being ignored and instead Healing you for X_1.",
            "Increase Frenzy's maximum stacks by 2. While at maximum stacks, you deal  X_0%[x] increased damage and your other Skills gain X_1%[+] increased Attack Speed.",
            "Increase the Critical Strike Damage of Meteor and Fireball by X_0%[x]. Double this bonus against Healthy targets.",
            "Increase the damage of arrows that ricochet from Barrage from 40% to X_0%. Barrage's arrows have a X_1% chance to split into 2 arrows whenever they ricochet.",
            "Increases your Critical Strike Damage by 100%[x]. The other properties on this weapon can roll higher than normal.",
            "Increases your Maximum Spirit by X_0 and Spirit Generation by 50%[x] while Grizzly Rage is active.",
            "Instead of detonating immediately, Corpse Explosion summons a Volatile Skeleton that charges at a random enemy and explodes. Corpse Explosion's damage is increased by X_0%[x].",
            "Iron Maiden is now also a Darkness Skill and deals Shadow damage. Enemies have a X_0% chance to be Stunned for 1 second when they take damage from Iron Maiden.",
            "Iron Skin grants Unstoppable, and X_0% Damage Reduction.",
            "Killing a Vulnerable enemy grants you X_0%[x] increased Energy Regeneration for 4 seconds.",
            "Killing an enemy while Berserking has a 40% chance to grant X_0% increased Cooldown Reduction to your Brawling Skills for 2 seconds.",
            "Killing an enemy with Shred grants Stealth for 2 seconds. Breaking Stealth with an attack grants guaranteed Critical Strikes for X_0 seconds.",
            "Killing an enemy with a Core Skill refunds X_0% of its base cost. Can only happen once per Skill cast.",
            "Killing an enemy with an Overpower leaves behind an Earthquake at their location, dealing X_0 Physical damage over 4 seconds. Can only occur once every 4 seconds.",
            "Kills extend the duration of your active Touch of Death swarms by 0.2 seconds. When a swarm is replaced early it bursts for X_0% of the damage it would have done.",
            "Kills have a 5% chance to summon a Wolf Companion to your side for X_0 seconds. This effect is a Lucky Hit against bosses. Maximum 3 additional wolves.In addition, gain +3 Ranks to Wolves.",
            "Knives from Dance of Knives have a 50% chance to shatter into 6 shards of metal on hit, dealing X_0 Physical damage.Dance of Knives now spends Combo Points, granting up to 3 additional Charges that can exceed the Maximum.",
            "Knocking Back an enemy grants you X_0% Movement Speed for 3 seconds.",
            "Landslide's earth pillars each strike a second time and deal an additional X_0% bonus damage per hit.",
            "Leap creates an Earthquake that deals X_0 Physical damage over 4 seconds. While standing in Earthquakes, you gain X_1% increased Damage Reduction.",
            "Lightning Storm Critical Strikes spawn 3 Dancing Bolts that seek enemies in the area dealing X_0 Lightning damage.",
            "Lightning Storm gains 1 additional strike each times it grows.Lightning Storm Critical Strikes cause lightning to strike twice, dealing X_0%[x] increased damage.",
            "Losing a stack of Resolve has a 10% chance to Heal you for X_0% of your Maximum Life.",
            "Lucky Hit: Centipede Skills have up to a 35% chance to spawn a Pestilent Swarm from the target which deals X_0 Poison damage per hit.Pestilent Swarms now also deal 100% of their Base damage as Poisoning damage over 6 seconds.",
            "Lucky Hit: Critical Strikes Have Up to a +X_0% Chance to Daze for 2 Seconds",
            "Lucky Hit: Critical Strikes Have Up to a +X_0% Chance to Immobilize for 2 Seconds",
            "Lucky Hit: Critical Strikes Have Up to a +X_0% Chance to Slow for 2 Seconds",
            "Lucky Hit: Critical Strikes Have Up to a +X_0% Chance to Stun for 2 Seconds",
            "Lucky Hit: Critical Strikes have up to a X_0% chance to grant a free Dark Shroud shadow.",
            "Lucky Hit: Critical Strikes with your Fury skills have up to a X_0% chance to extend the duration of Berserking by 1 second.",
            "Lucky Hit: Critical strikes with your Fury skills have up to a X_0% chance to extend the duration of Berserking by 1 second.",
            "Lucky Hit: Critically Striking an enemy has up to a 100% chance to Fear and Slow them by X_0% for 4 seconds.",
            "Lucky Hit: Damage from your Pyromancy Skills has up to a X_0% chance to restore 10 Mana.",
            "Lucky Hit: Damaging Bleeding or Vulnerable enemies has up to a X_0% chance to Stun them for 2 seconds.",
            "Lucky Hit: Damaging a Vulnerable enemy has up to a 25% chance to create an Arrow Storm at the enemy's location , dealing X_0 Physical damage over 3 seconds. Your Arrow Storms deal X_1% increased damage.",
            "Lucky Hit: Damaging a Vulnerable enemy with a Non-Basic Skill has a X_0% chance to drop a Healing Potion.",
            "Lucky Hit: Damaging an enemy has up to a 20% chance to Daze them for 2 seconds.You deal X_0%[x] increased damage to Dazed enemies.",
            "Lucky Hit: Damaging an enemy with a Core Skill has up to a X_0% chance to extend the duration of Berserking by 2 seconds. Double this duration if it was a Critical Strike.",
            "Lucky Hit: Damaging an enemy with a Marksman or Cutthroat Skill has up to a X_0% chance to summon a Shadow Clone that mimics that Skill.",
            "Lucky Hit: Damaging an enemy with a Mobility Skill has up to a X_0% chance to fully restore your Energy. Your Maximum Energy is increased by X_0.",
            "Lucky Hit: Damaging enemies with Leap, Upheaval, or Whirlwind have up to a X_0% chance to summon an Ancient to perform the same Skill. Can only occur once every 5 seconds.",
            "Lucky Hit: Dealing damage to enemies affected by your Trap Skills has a X_0% chance to cause an explosion that deals X_1 Shadow damage and applies Vulnerable for 2 seconds.",
            "Lucky Hit: Dealing direct damage to a Vulnerable enemy has up to a X_0% chance to Daze them for 2 seconds.",
            "Lucky Hit: Eagle Skills have up to a X_0% chance to spawn a Storm Feather at your target. Each Storm Feather you pick up now also reduces your Mobility Skill Cooldowns by 0.5 seconds.",
            "Lucky Hit: Hitting a Vulnerable enemy has up to a X_0% chance to reduce your Evade Cooldown by 5 seconds. Each stack of Ferocity you have increases this chance by 3%.",
            "Lucky Hit: Inflicting Bleeding on an enemy has up to a X_0% chance to reduce the Cooldowns of your Skills by 1.0 second.",
            "Lucky Hit: Poison Imbued Skills have up to a 15% chance to create a toxic pool that deals X_0 Poisoning damage over 3 seconds to enemies within. While standing in the pool, your Poison Imbuement Skill has no Cooldown and no Charge limit.",
            "Lucky Hit: Shadow Imbued Skills have up to a 75% chance to create a toxic explosion that applies X_0 Poisoning damage over 5 seconds to the target and surrounding enemies. If they were already Poisoned, increase this damage by 100%[x].",
            "Lucky Hit: Shadow Imbued Skills have up to a 75% chance to release an explosion that deals X_0 Cold damage to the target and surrounding enemies, Chilling them for 25%. If they were already Chilled or Frozen, increase this damage by 100%[x].",
            "Lucky Hit: Storm Skills have up to a X_0% chance to grant 4 Spirit.Your base Storm Skills are now also Werewolf Skills.",
            "Lucky Hit: Up to a +30.0% Chance to Deal X_0% Bleeding Damage over 5 seconds",
            "Lucky Hit: Up to a +X_0% Chance to Apply a Random Crowd Control Effect for 2 Seconds",
            "Lucky Hit: Up to a +X_0% Chance to Daze for 2 Seconds",
            "Lucky Hit: Up to a +X_0% Chance to Execute Injured Non-Elites",
            "Lucky Hit: Up to a +X_0% Chance to Fear for 2 Seconds",
            "Lucky Hit: Up to a +X_0% Chance to Freeze for 2 Seconds",
            "Lucky Hit: Up to a +X_0% Chance to Immobilize for 2 Seconds",
            "Lucky Hit: Up to a +X_0% Chance to Make Enemies Vulnerable for 0 Seconds",
            "Lucky Hit: Up to a +X_0% Chance to Make Enemies Vulnerable for 2 Seconds",
            "Lucky Hit: Up to a +X_0% Chance to Slow for 2 Seconds",
            "Lucky Hit: Up to a +X_0% Chance to Stun for 2 Seconds",
            "Lucky Hit: Up to a 10% Chance to Gain +X_0% Damage for 4 Seconds",
            "Lucky Hit: Up to a 10% chance to generate X_0 Essence when hitting a Vulnerable enemy.",
            "Lucky Hit: Up to a 100% chance to lose all of your Resource.",
            "Lucky Hit: Up to a 100% chance when hitting or killing a Frozen enemy to create ice splinters that fires away from them dealing X_0 Cold damage and Chilling for X_1%.",
            "Lucky Hit: Up to a 15% Chance to Heal +X_0 Life",
            "Lucky Hit: Up to a 15% Chance to Restore +X_0% Primary Resource",
            "Lucky Hit: Up to a 20% chance to Freeze enemies for 3 seconds and deal X_0 Cold damage to them.",
            "Lucky Hit: Up to a 20% chance to trigger a poison nova that applies 4,500 Poisoning damage over 5 seconds to enemies in the area.",
            "Lucky Hit: Up to a 25% chance to deal 900 Shadow damage to surrounding enemies and reduce their damage dealt by 20% for 5 seconds.",
            "Lucky Hit: Up to a 25%[+] chance to create a Mystic Circle for 10 seconds. Casting a Centipede Skill in Mystic Circles empowers them to periodically apply X_0 Poisoning damage over 6 seconds to enemies within while extending any Crowd Controls affecting them.",
            "Lucky Hit: Up to a 25%[+] chance to create a Mystic Circle for 10 seconds. Casting a Eagle Skill in Mystic Circles empowers them to move with you and explode for X_0 Lightning damage when you Evade.",
            "Lucky Hit: Up to a 25%[+] chance to create a Mystic Circle for 10 seconds. Casting a Gorilla Skill in Mystic Circles empowers them to periodically grant you a Barrier for 25% of your Maximum Life which increases your Armor by X_0%[+] while active.",
            "Lucky Hit: Up to a 25%[+] chance to create a Mystic Circle for 10 seconds. Casting a Jaguar Skill in Mystic Circles empowers them to keep your Ferocity at Maximum and increase your damage by X_0%[x] per stack while you remain within.",
            "Lucky Hit: Up to a 30% chance to form an exploding Ice Spike, dealing X_0 Cold damage. Triple this chance if the enemy is Frozen.",
            "Lucky Hit: Up to a 40% Chance to Deal +X_0 Cold Damage",
            "Lucky Hit: Up to a 40% Chance to Deal +X_0 Fire Damage",
            "Lucky Hit: Up to a 40% Chance to Deal +X_0 Lightning Damage",
            "Lucky Hit: Up to a 40% Chance to Deal +X_0 Physical Damage",
            "Lucky Hit: Up to a 40% Chance to Deal +X_0 Poison Damage",
            "Lucky Hit: Up to a 40% Chance to Deal +X_0 Shadow Damage",
            "Lucky Hit: Up to a X_0% chance for your Cutthroat Skills to Slow by 40% for 3 seconds and your Marksman Skills to Knock Back enemies.",
            "Lucky Hit: Up to a X_0% chance for your Skills to inflict Vampiric Curse on enemies. Enemies afflicted with Vampiric Curse are also Vulnerable. Vampiric Curse's stored souls deal X_1% increased damage.",
            "Lucky Hit: Up to a X_0% chance of inflicting Fear for 4 seconds. Feared enemies are Chilled for 20% every second.",
            "Lucky Hit: Up to a X_0% chance to Freeze enemies for 3 seconds.",
            "Lucky Hit: Up to a X_0% chance to reduce the Cooldown of your Ultimate Skill by 2 seconds. Can only happen once per Skill cast.",
            "Lucky Hit: Up to a X_0% chance when dealing Lightning damage to overload the target for 3 seconds, causing any direct damage you deal to them to pulse X_1 additional damage to surrounding enemies.",
            "Lucky Hit: When you hit a Crowd Controlled enemy, there is up to a X_0% chance for that Crowd Control effect to spread to another unaffected enemy.",
            "Lucky Hit: When your Conjuration Skills hit you have up to a X_0% chance to gain +1 Rank to your Conjuration skills for 12 seconds.  This can stack up to 5 times.",
            "Lucky Hit: While you have an active Bone Storm, hitting an enemy outside of a Bone Storm has up to a X_0% chance to spawn an additional Bone Storm at their location. Each of your active Sacrifice bonuses increases this chance by 25% and the maximum possible Bone Storms by +1.",
            "Lucky Hit: You have up to a 20% chance to launch 6 Stun Grenades that deal X_0 Physical damage and Stun enemies for 1.00 seconds.",
            "Lucky Hit: You have up to a 50% chance to spawn a pool of blood that will heal you if you stand in it.",
            "Lucky Hit: You have up to a X_0% chance to gain 20 Fury when a Core Skill deals direct damage to at least one Bleeding enemy. Your Maximum Fury is increased by X_1.",
            "Lucky Hit: Your Marksman and Cutthroat Skills have up to a 25% chance to create an Arrow Storm at the enemy's location, dealing X_0 Physical damage over 3 seconds. Your Arrow Storms deal X_1% increased damage.",
            "Lucky Hit: Your Shadow Damage Over Time effects have a X_0% chance to generate 3 Essence. Damage from your Shadowblight Key Passive will always generate 2 Essence.",
            "Lucky Hit: Your damage over time effects have up to a 75% chance to erupt, dealing X_0 damage of the same type to surrounding enemies.",
            "Maximum Ferocity increased by X_0.",
            "Meteor's Mana cost is replaced with a X_0 second Cooldown and 2 total Charges. Casting Meteor drops 3 additional Meteors around the target. Its Enchantment and Enhancement drop 1 additional Meteor instead.",
            "Meteorites fall around Meteor, dealing X_0% of Meteor's damage on impact. Your Meteorites additionally Burn enemies they hit for X_1 damage over 6 seconds.",
            "Mighty Throw deals X_0%[x] increased damage and hurls another 2 weapons.",
            "Minions Inherit +X_0% of Your Thorns",
            "Moving with a Mobility Skill or Evade spawns Storm Feathers along behind you. Storm Feathers now drop from the sky as they are spawned, each dealing X_0 Lightning damage on impact.",
            "Objects will often break around you",
            "Once an enemy is Slowed by at least 80%, they also become Feared for 3 seconds. You deal X_0%[x] increased damage to Feared enemies.",
            "Overrides Male Necromancer evade to a prototype version. Evade animation speed scales with slow or chill CC effects.",
            "Overrides Male Necromancer evade to a prototype version. Evade is disabled when the player is affected by slow or chill CC type.",
            "Payback now echoes forward and additionally deals X_0% of your Thorns damage to enemies.",
            "Penetrating Shot makes enemies Vulnerable for 3 seconds. Every 4th cast of Penetrating Shot bounces off walls and deals X_0%[x] more damage.",
            "Periodically reapplies the PvP Balance Buff.",
            "Poison Creeper's active also casts Landslide in a circle around you. Earth Skills deal X_0% increased damage to Poisoned enemies.",
            "Poison Trap no longer breaks Stealth and triggers no Cooldown or arm time while you are in Stealth. All Poison Traps activate when you exit Stealth and Poison Trap's Cooldown will be X_0 seconds per trap placed.",
            "Prints a debug message with each damage instance's Attack Speed.",
            "Pulverize creates a shockwave that travels forward, dealing X_0% of its damage to targets in the path.",
            "Pulverize is now also an Earth Skill. After casting Pulverize, tectonic spikes continue to deal X_0 damage over 2 seconds.",
            "Quill Volley's feathers explode at their apex and return to where they were cast, both dealing X_0% of their normal damage.",
            "Rake manifests a Spirit Jaguar which also casts Rake at a random enemy, dealing X_0% Base damage.",
            "Rapid Fire has a X_0% chance to ricochet to another target.",
            "Rapid Fire now lobs exploding arrows that deal X_0%[x] increased damage.",
            "Ravager no longer has a duration and instead drains X_0 Vigor every second until depleted. Effects which extend Ravager instead generate Vigor.",
            "Ravens now deal Lightning damage which is increased by X_0%. Ravens Active deals its full damage in half the time, and enemies inside it are Stunned.",
            "Razor Wings is now also an Incarnate Skill with:Passive: You are Unhindered.Active: You gain X_0%[+] increased Movement Speed per active Razor Wing.",
            "Reduces the Cooldown of Leap by X_0 seconds per enemy hit, up to a maximum of 7 seconds. Hitting a Boss with Leap provides the maximum reduction.",
            "Rend's duration is increased by X_0 seconds. Damaging enemies with your Brawling Skills applies 2 stacks of Rend's Bleed. This can only affect each enemy once every 1 second.",
            "Restore X_0 of your Primary Resource when you Crowd Control an enemy.",
            "Scourge continuously affects enemies around you for X_0 seconds.",
            "Sever leaves behind Desecrated Ground that deals X_0 Shadow damage over 2 seconds.",
            "Sever no longer returns and instead splits into 3 specters that expand out from its apex. Sever deals X_0% of normal damage.",
            "Sever now dashes you forward to attack instead. It is now also a Mobility Skill and costs no Essence, but has a X_0 second Cooldown.",
            "Shadow Imbue Lasts For +X_0 Cast",
            "Shadow Step Cleaves for +X_0% Damage",
            "Shadow Step has an additional Charge. Killing an enemy with Shadow Step refunds a Charge and increases the damage of Shadow Step by X_0%[x] for 2 seconds, up to X_1%[x].",
            "Shapeshifting into a new animal form increases the value of your Bestial Rampage bonuses by X_0%[+], up to X_1%[+]. This bonus decays by 2% per second.",
            "Shred gains a 4th dash attack that hits all surrounding enemies, deals X_0%[x] increased damage, and Knocks Down enemies for 2 seconds.",
            "Skeletal Mages Inherit +X_0% of Your Thorns",
            "Skeletal Priests empower your Skeletal Warriors' attacks to deal Shadow damage and have a X_0% chance to Stun enemies for 1.5 seconds.",
            "Skeletal Warriors Inherit +X_0% of Your Thorns",
            "Skills deal up to X_0%[x] increased damage based on your available Primary Resource when cast, receiving the maximum benefit while you have full Primary Resource.",
            "Skills using this weapon deal X_0%[x] increased damage per point of Fury you have, but you lose 10 Fury every second.",
            "Smoke Grenade has a X_0% chance to create an Arrow Storm where it explodes, dealing X_1 Physical damage over 3 seconds. Your Arrow Storms continuously apply Vulnerable.",
            "Soar Deals Up to +X_0% Damage Based on Distance Traveled",
            "Soar Grants X_0% Maximum Life as Barrier for 1.5 Seconds",
            "Soar casts Vortex at its landing location and deals X_0%[x] increased damage.",
            "Soulrift's duration is increased by 0.5 seconds for every 30 Essence you spend while it's active, up to 8 seconds. Soulrift deals X_0% of its Shadow damage per second to surrounding enemies for every 30 Essence you gain while its active.",
            "Spending Vigor Heals you for X_0% of your Maximum Life. Every second, your active Cooldowns each drain 10% Maximum Life from you to reduce their durations by 3 seconds.",
            "Spending your Primary Resource reduces the Resource cost of your Skills and increases your damage by 10%[x] for 3 seconds, up to 50%[x].",
            "Steel Grasp Stuns for +X_0 Seconds",
            "Steel Grasp and Iron Maelstrom launch up to 10 metal shards that deal X_0 Physical damage.",
            "Steel Grasp launches 2 additional chains. Enemies hit by Steel Grasp take X_0%[x] increased damage from you for 5 seconds.",
            "Stinger spawns a Pestilent Swarm at its target location which deals X_0% of Stinger's Base damage per hit.Pestilent Swarms now last 100%[+] longer and spiral outwards.",
            "Stone Burst causes enemies to take X_0%[x] increased damage from your other Skills for 5 seconds. Stone Burst's Spirit cost is reduced by 10.",
            "Stone Burst deals X_0%[x] increased damage and when Cast at or above 75 Spirit, it is immediately at its maximum size.",
            "Stunning a Bleeding enemy deals X_0% of their total Bleeding amount to them as Physical damage.",
            "Succumb to hatred and earn Mother's Favor, increasing your damage dealt by 60%[x]. Slaughter enemies to briefly steal Mother's Favor from Nearby allies.",
            "Taking direct damage has a X_0% chance to reset the Cooldown of one of your Defensive Skills.",
            "Teleport's Cooldown is reduced by X_0 seconds. After Teleporting, Crackling Energy hits 2 additional enemies for 5 seconds.",
            "The Avalanche Key Passive now applies to 2 additional casts.",
            "The Earthen Might Key Passive also applies to your Storm Skills.",
            "The Ossified Essence Key Passive also increases the Critical Strike Damage of your Bone Skills by 1%[x] per Essence above 50, up to X_0%[x].",
            "The Vyr's Mastery Key Passive also grants X_0% Damage Reduction, tripled while fighting a Close enemy.",
            "The duration of Earthen Bulwark is increased by 6 seconds. In addition, killing an enemy replenishes X_0 of your active Earthen Bulwark's Barrier.",
            "The duration of Grizzly Rage is increased by X_0 seconds. In addition, Critical Strikes while Grizzly Rage is active increase your Critical Strike Damage by 10%[x] for the duration, up to a maximum of 200%[x].",
            "The first direct damage you deal to an enemy is a guaranteed Critical Strike. When you consume stacks of Precision casting a Skill, that Skill gains X_0%[x] increased Critical Strike Damage and you gain X_1 Energy.",
            "This is the descriptive text for the Legendary Power 1 affix.Dash no longer costs Energy but instead has a 5 second cooldown. In addition, enemies hit by Dash deal X_0% less damage to you for 7 seconds.",
            "This is the descriptive text for the Legendary Power 2 affix",
            "This is the descriptive text for the Unique Power 1 affix.Lucky Hit: Your ranged attacks have up to a 30% chance to deal X_0% additional damage and knock the target back.",
            "Thorns damage dealt has a X_0% chance to deal damage to all enemies around you and your Minions.",
            "Thorns has a 10% chance to deal X_0%[x] increased damage.",
            "Tornado will seek up to X_0 targets.",
            "Trample now summons 6 Landslide pillars of earth during its duration that deal X_0% normal damage. Trample is now also a Nature Magic and Earth Skill.",
            "Traps Arm X_0 Seconds Faster",
            "Twisting Blades Returns +X_0% Faster",
            "Twisting Blades orbit for a short time after they return to you, dealing X_0% of Twisting Blades' return damage per hit.",
            "Unstable Currents has a X_0% chance to cast an additional Shock Skill.",
            "Up to X_0 Distant enemies are pulled toward you while you have an Ultimate Skill active.",
            "Upheaval Overpowers Stun for +X_0 Seconds",
            "Upheaval ignites the ground Burning enemies for an additional X_0 damage over 4 seconds increased by 25%[x] for every 100 Strength you have.Current Bonus: 0%[x]",
            "Upon collecting Crackling Energy, there's a 15% chance to release a lightning nova, dealing X_0 Lightning damage, increased by 120%[x] for every 100 Intelligence you have.Current Bonus: 0%[x]",
            "Upon taking damage from surrounding enemies, you drop a Smoke Grenade and Dodge the next X_0 attacks within 5 seconds. Can only occur once every 10 seconds.",
            "Using 3 consecutive Basic Skills creates a 5 second mark on the ground under the first enemy is hit. Moving away from the mark increases Critical Chance up to X_0. The bonus ends when the mark disappears, and a new mark can only be created after the previous one vanishes.",
            "Using a Cooldown restores X_0 Mana.",
            "Using a Healing Potion grants X_0%[+] Movement Speed for 3 seconds, and you may now drink them while at full Life.Every 20 meters you travel, you spawn a Healing Potion.",
            "Using a Healing Potion makes a free Dark Shroud shadow. Each shadow grants X_0% increased Damage Reduction.You may now drink your Healing Potion while at full Life.",
            "Using an Agility Skill reduces the Cooldown of your next Subterfuge Skill by 20%. Using a Subterfuge Skill increases the damage of your next Agility Skill by X_0%[x].",
            "Walking near a Corpse automatically activates an equipped Corpse Skill every second, dealing X_0%[x] reduced damage.",
            "Werebear form is now your true form, and you gain X_0 Ranks to all Werebear Skills.",
            "Werewolf Skills function as Storm Skills and Werebear Skills function as Earth Skills for the Nature's Fury Key Passive.",
            "Werewolf form is now your true form, and you gain X_0 Ranks to all Werewolf Skills.",
            "When Bone Spirit explodes inside a Bone Prison, it explodes an additional time, dealing X_0% of normal damage. Bone Spirit now prioritizes enemies inside a Bone Prison.",
            "When Bone Spirit explodes, it spawns 3 spirits that seek nearby enemies and deal X_0% of its damage. This effect can only occur once per cast.",
            "When Decompose explodes, gain X_0 Essence.",
            "When Rabies infects an enemy, reduce its Cooldown by X_0 seconds. This Cooldown reduction is tripled when infecting Elite enemies.",
            "When X_0 Close enemies, automatically cast Cyclone Armor. This cannot occur more than once every X_1 seconds.",
            "When a Dark Shroud shadow is removed you trigger an explosion that deals X_0 Shadow damage and applies Shadow Imbuement to each enemy it hits.",
            "When a segment of Bone Prison is destroyed or expires, it deals X_0 damage in an area around itself.",
            "When activating or refreshing the full Walking Arsenal Key Passive effect, you automatically cast Ground Stomp and gain X_0 Fury. Can only occur once every 10 seconds.",
            "When an enemy falls below 35% Life while afflicted with any of your damage over time effects, an explosion occurs every 7 seconds, dealing X_0 damage for each element they're afflicted by.",
            "When at least 0 enemies are near you, gain an aura that automatically curses surrounding enemies with Decrepify for X_0 seconds.",
            "When cast below 50% Maximum Resource, Basic Skills generate 3 of your Primary Resource, once per cast.When cast at or above 50% Maximum Resource, Basic Skills deal X_0%[x] increased damage.",
            "When casting an Imbuement Skill you trigger an explosion around yourself, applying the Imbuement effects and dealing X_0 damage of the same type.",
            "When casting an Ultimate Skill and again 5 seconds after, you Pull in Distant enemies and deal X_0 Physical damage to them. This damage is increased by 2.00%[x] per 1 point of Willpower you have.Current Bonus: 0%[x]",
            "When consuming a Corpse, there is a X_0% chance to also create a decaying Skeletal Simulacrum that seeks enemies but cannot attack. When it dies, it explodes for 450 Shadow damage. This effect is treated as a Macabre Skill.",
            "When entering Stealth, create a cloud that deals X_0 Poison damage over 6 seconds.",
            "When falling below 20 Spirit, there is a X_0% chance to fully restore your Spirit.",
            "When first damaging Elite, and for 5 seconds after, your Thorn deals X_0 damage and allows you to heal 10% of your total health per second.",
            "When hit while not Healthy, a magical bubble is summoned around you for X_0 seconds. While standing in the bubble, Players are Immune. Can only occur once every 90 seconds.",
            "When newly Burning an enemy, you deal an additional X_0 Fire damage to them.This effect is treated as a Pyromancy Skill.",
            "When the Nature's Fury Key Passive triggers a free Skill, your non-Ultimate Cooldowns of the opposite type are reduced by X_0 seconds.",
            "When there are at least 5 Close enemies, Stun them for X_0 seconds. Can only occur once every 20 seconds.",
            "When you Critically Strike, you regain X_0%[+] of the Vigor you've spent in the last 2 seconds.Gain Counterattack's Passive Effect.",
            "When you Dodge or Block an attack you trigger a X_0% Thorns attack against the attacker.",
            "When you Evade or Shadow Step, you drop a cluster of exploding Stun Grenades that deal X_0 total Physical damage and Stun enemies for 1.00 second.Your Stun Grenades deal X_1% increased damage.",
            "When you Evade you turn into a cloud of bats, becoming Unstoppable for 2.5 seconds. Enemies along your path take 300 Physical damage and are inflicted with Vampiric Curse.  Evade's Cooldown is increased by X_0 seconds.",
            "When you Freeze an enemy there is a X_0% chance they become Vulnerable for 3 seconds.",
            "When you Freeze an enemy, you deal X_0 Cold damage to them.This effect is treated as a Frost Skill.",
            "When you Stun, Freeze, or Immobilize an Elite, you Pull In all Nearby enemies and deal X_0%[x] increased damage to them for 3 seconds. Can only occur once every 12 seconds.Against Bosses, this effect triggers when dealing damage instead.",
            "When you are afflicted with a Crowd Control effect, there is a X_0% chance that the same enemy and enemies around you are also afflicted with the same effect for 3.5 seconds.",
            "When you are hit with a Stun, Freeze or Knock Down effect, there is a X_0% chance to automatically activate Grizzly Rage for 6 seconds.",
            "When you cast Blizzard it will periodically spawn exploding Ice Spikes that deal X_0 damage. Your Ice Spikes Chill enemies for 15%.",
            "When you cast a Skill with a Cooldown, you explode, dealing X_0 Fire damage.",
            "When you cast a Skill, you heal for X_0% Life. Double this bonus while below 50% Life.",
            "When you cast an Incarnate Skill enemies around you become Vulnerable for 5 seconds. Killing a Vulnerable enemy echoes X_0% of the killing blow's damage to another Nearby enemy.",
            "When you cast an Ultimate Skill, your next Core Skill consumes all of your Energy and deals X_0%[x] increased damage per Energy consumed.Using a Cooldown restores 25 Energy.",
            "When you cast the 3rd attack of your Basic Skills you have a X_0% chance to unleash Payback.",
            "When you enter or break Stealth, you drop a cluster of exploding Stun Grenades around you that deal X_0 total Physical damage and Stun enemies for 1.00 second.Your Stun Grenades deal X_1% increased damage.",
            "When you evade, you turn into a cloud of bats.",
            "When you first hit an enemy with an Earth Skill, Petrify them for 3 seconds. Petrifying an enemy additionally deals X_0 Physical damage.",
            "When you gain Berserking while already Berserk, you have a X_0% chance to become more enraged granting 15%[x] increased damage, 2 Fury per second, and 10% Cooldown Reduction.",
            "When you have Fortify your Earth Skills gain +2 Ranks.",
            "When you hit at least 1 enemy with Maul, increase its Attack Speed by X_0%[+] and you gain X_1%[x] Damage Reduction for 5 seconds, stacking up to 5 times.",
            "When you pick up X_0 Blood Orbs, a free Bone Spirit is spawned, dealing bonus damage based on your current Life percent.",
            "When you spend 75 Energy you release a cluster of exploding Stun Grenades that deal X_0 total Physical damage and Stun enemies for 1.00 seconds.Your Stun Grenades deal X_1% increased damage.",
            "When you take damage from a Non-Physical damage type, you gain X_0%[+] Maximum Resistance to that damage type for 6 seconds. This effect can only apply to one damage type at a time.",
            "When you use a Shapeshifting Skill that changes your form, Heal for X_0% of your Maximum Life. If you are at full Life, gain the same amount as Fortify.",
            "When you use a Subterfuge Skill, leave behind an unstable Shadow Decoy Trap that Taunts enemies. The Shadow Decoy Trap will explode after 3.5 seconds dealing X_0 Shadow damage. Cannot occur more than once every 5 seconds.",
            "When you would accumulate 50% of your Maximum Life lost, you automatically cast an equipped Defensive, Subterfuge or Macabre Skill before receiving damage. Can only occur once every X_0 seconds and cannot cast Mobility Skills.",
            "When you would be damaged for at least 40% of your Maximum Life at once, it is instead distributed over the next 2 seconds and reduced by X_0%.",
            "When you would take direct damage, it is instead distributed as Poisoning over the next 10 seconds but increased by X_0%.Lucky Hit: Direct damage has up to a 25% chance based on your Poisoned Life percentage to infect an enemy with Touch of Death.",
            "When your Core Skills Overpower an enemy, you spawn 3 Volatile Blood Drops. Collecting a Volatile Blood Drop causes it to explode, dealing X_0 Physical damage around you. Every 20 seconds, your next Skill is guaranteed to Overpower.",
            "Whenever Penetrating Shot damages an enemy, 2 additional arrows split off to either side. These side arrows deal X_0% of Penetrating Shot's Base damage and do not split.",
            "Whenever an enemy hits you, your Dodge Chance against that enemy increases by X_0%[+]. Successfully Dodging resets this bonus.",
            "Whenever you cast a Shout Skill, its active Cooldown is reduced by X_0% per Nearby enemy, up to a maximum of 50.0%.",
            "Whenever you deal direct damage while Berserking, inflict X_0% of the Base damage dealt as additional Bleeding damage over 5 seconds.",
            "Whenever your Blood Skills Overpower you gain X_0%[+] Attack Speed for 4 seconds.",
            "While Army of the Dead is active, your Minions gain X_0%[x] increased damage and take 90% reduced damage.",
            "While Berserking, you deal X_0 Fire damage every second to surrounding enemies.",
            "While Cataclysm is active, you gain unlimited Spirit and deal X_0%[x] increased damage.",
            "While Channeling Incinerate, you periodically shoot embers that are attracted to enemies, each dealing X_0 Fire damage.",
            "While Channeling Stone Burst and for 2 seconds afterwards, you gain 15% Damage Reduction.Stone Burst's final explosion deals X_0%[x] increased damage, further increased by X_0%[x] for each size increase.",
            "While Deep Freeze is active, exploding Ice Spikes form in the area, dealing X_0 Cold damage. Your Ice Spikes have a 50% increased explosion radius.",
            "While Deep Freeze is active, you restore X_0% of your Maximum Life and Mana per second.",
            "While Grizzly Rage is active, Skills that Critically Strike apply Rabies and your Poison damage is increased by X_0%.",
            "While Hurricane is active, gain +5 Ranks to your Shapeshifting Skills.",
            "While Ice Armor is active, you leave behind exploding Ice Spikes that deal X_0 damage. Your Ice Spikes deal 15%[x] increased damage to Frozen enemies.",
            "While Injured, Your Potion Also Grants 20% Maximum Life as Barrier",
            "While Injured, Your Potion Also Grants 30% Movement Speed for 2 Seconds",
            "While Injured, Your Potion Also Restores 20% Resource",
            "While Unstable Currents is not active, your Shock Skills have a X_0% chance to trigger a free cast from it.",
            "While Unstoppable and for 4 seconds after, you gain X_0%[+] increased Movement Speed and are Unhindered.",
            "While above X_0 Fury, you are Berserk, but lose 2 Fury per second.",
            "While active, Counterattack reflects incoming Razor Wings back out again. Other Small Missiles are also deflected back at enemies and deal X_0% of Counterattack's Base damage on impact.",
            "While at full Life, your Skills unleash a divine barrage dealing 195 damage.",
            "While at maximum stacks of the Momentum Key Passive, your Cutthroat Skills deal X_0% increased damage and you become Unstoppable for 3 seconds every 8 seconds.",
            "While below X_0% Life, Evade Freezes Close enemies, deals 450 Cold damage to them, and makes them Vulnerable for 3 seconds.",
            "While below X_0% Life, you receive X_1%[+] more Healing from all sources.",
            "While both bonuses from the Esu's Ferocity Key Passive are active, your Attack Speed is increased by X_0%.",
            "While channeling Incinerate, your Burning damage is increased by X_0%[x]. Enemies damaged by Incinerate explode, Burning all surrounding enemies for X_1 over 6 seconds. Can only occur once every 3 seconds.",
            "While dashing, Shred seeks out Nearby Poisoned and deals X_0%[x] increased damage to them.",
            "While in Werebear form, you gain X_0 Spirit every 8 seconds.",
            "While mounted you take 45% less damage.",
            "While mounted, gain a shield after not taking damage for 8 seconds, absorbing up to 15% of your Maximum Life. The shield goes away after dismount.",
            "While mounted, you stun the attacker for 4 seconds when you get hit.",
            "While moving, you gain X_0% Dodge Chance.After standing still for 2 seconds, you become Unstoppable until you move.",
            "While using this weapon, damaging at least one enemy with Rupture creates a blood pool that inflicts X_0 Bleeding damage over 6 seconds. Enemies standing in the pool take 30%[x] increased damage.",
            "While within your own Blizzard and for 3 seconds after leaving it, you take X_0% less damage.",
            "While you have at least 4 stacks of Ferocity, you gain X_0 Vigor per second.Gaining Ferocity also grants Resolve.",
            "While you have both bonuses from the Close Quarters Combat Key Passive active, your Dodge Chance increases by X_0% whenever you're hit by an enemy. Successfully Dodging resets this bonus.",
            "While you have unlimited Energy from Inner Sight, casting a Core Skill has a X_0% chance to spawn Caltrops, Poison Trap, or Death Trap.",
            "While your Spirit Hall choices match: Their bonuses are 100% more potent. Skills of their Base Spirit gain X_0% Vigor Cost Reduction. Skills of their Base Spirit gain X_0% Cooldown Reduction.",
            "Whirlwind explodes every 2 seconds and after it ends, dealing X_0% of the Base damage dealt to surrounding enemies as Fire damage.",
            "Whirlwind leaves behind Dust Devils that deal X_0 damage to enemies in their path.",
            "Whirlwind periodically Pulls enemies to you.",
            "Whirlwind's Critical Strike Chance is increased by X_0%[+] for each second it is channeled, up to X_1%[+].",
            "Wind Shear now deals X_0 Poisoning damage over 4 seconds.Lucky Hit: Wind Shear has up to a X_1% chance to fully restore your Spirit.",
            "X_0% Agility Cooldown Reduction",
            "X_0% Basic Resource Generation",
            "X_0% Blood Howl Cooldown Reduction",
            "X_0% Blood Mist Cooldown Reduction",
            "X_0% Bone Prison Cooldown Reduction",
            "X_0% Boulder Cooldown Reduction",
            "X_0% Brawling Cooldown Reduction",
            "X_0% Call of the Ancients Cooldown Reduction",
            "X_0% Caltrops Cooldown Reduction",
            "X_0% Cataclysm Cooldown Reduction",
            "X_0% Challenging Shout Cooldown Reduction",
            "X_0% Charge Cooldown Reduction",
            "X_0% Companion Cooldown Reduction",
            "X_0% Concealment Cooldown Reduction",
            "X_0% Cooldown Reduction",
            "X_0% Cyclone Armor Cooldown Reduction",
            "X_0% Damage Reduction for Your Minions",
            "X_0% Damage Reduction from Bleeding Enemies",
            "X_0% Damage Reduction from Burning Enemies",
            "X_0% Damage Reduction from Close Enemies",
            "X_0% Damage Reduction from Distant Enemies",
            "X_0% Damage Reduction from Elites",
            "X_0% Damage Reduction from Enemies Affected by Curse Skills",
            "X_0% Damage Reduction from Enemies Affected by Trap Skills",
            "X_0% Damage Reduction from Poisoned Enemies",
            "X_0% Damage Reduction from Shadow Damage Over Time-Affected Enemies",
            "X_0% Damage Reduction per Crackling Energy Charge",
            "X_0% Damage Reduction while Fortified",
            "X_0% Damage Reduction while Healthy",
            "X_0% Damage Reduction while Injured",
            "X_0% Damage Reduction while Unstoppable",
            "X_0% Damage Reduction",
            "X_0% Dark Shroud Cooldown Reduction",
            "X_0% Death Blow Cooldown Reduction",
            "X_0% Debilitating Roar Cooldown Reduction",
            "X_0% Deep Freeze Cooldown Reduction",
            "X_0% Defensive Cooldown Reduction",
            "X_0% Dodge Chance Against Close Enemies",
            "X_0% Dodge Chance Against Distant Enemies",
            "X_0% Dodge Chance",
            "X_0% Earthen Bulwark Cooldown Reduction",
            "X_0% Energy Cost Reduction",
            "X_0% Essence Cost Reduction",
            "X_0% Evade Cooldown Reduction",
            "X_0% Focus Cooldown Reduction",
            "X_0% Frost Nova Cooldown Reduction",
            "X_0% Fury Cost Reduction",
            "X_0% Gem Strength in this Item",
            "X_0% Golem Active Cooldown Reduction",
            "X_0% Grizzly Rage Cooldown Reduction",
            "X_0% Ground Stomp Cooldown Reduction",
            "X_0% Hurricane Cooldown Reduction",
            "X_0% Hydra Resource Cost Reduction",
            "X_0% Ice Blades Cooldown Reduction",
            "X_0% Imbuement Cooldown Reduction",
            "X_0% Impairment Reduction",
            "X_0% Incarnate Cooldown Reduction",
            "X_0% Inferno Cooldown Reduction",
            "X_0% Iron Maelstrom Cooldown Reduction",
            "X_0% Iron Skin Cooldown Reduction",
            "X_0% Kick Cooldown Reduction",
            "X_0% Lacerate Cooldown Reduction",
            "X_0% Leap Cooldown Reduction",
            "X_0% Lightning Spear Cooldown Reduction",
            "X_0% Mana Cost Reduction",
            "X_0% Maximum Life",
            "X_0% Maximum Minion Life",
            "X_0% Mobility Cooldown Reduction",
            "X_0% Nature Magic Cooldown Reduction",
            "X_0% Petrify Cooldown Reduction",
            "X_0% Poison Creeper Cooldown Reduction",
            "X_0% Poison Trap Cooldown Reduction",
            "X_0% Potency Cooldown Reduction",
            "X_0% Puncture Resource Generation",
            "X_0% Rabies Cooldown Reduction",
            "X_0% Rain of Arrows Cooldown Reduction",
            "X_0% Ravens Cooldown Reduction",
            "X_0% Resource Cost Reduction",
            "X_0% Resource Generation and Maximum",
            "X_0% Resource Generation",
            "X_0% Rupture Cooldown Reduction",
            "X_0% Shout Cooldown Reduction",
            "X_0% Slow Duration Reduction",
            "X_0% Smoke Grenade Cooldown Reduction",
            "X_0% Soar Cooldown Reduction",
            "X_0% Spirit Cost Reduction",
            "X_0% Steel Grasp Cooldown Reduction",
            "X_0% Storm Cooldown Reduction",
            "X_0% Subterfuge Cooldown Reduction",
            "X_0% Teleport Cooldown Reduction",
            "X_0% The Devourer Cooldown Reduction",
            "X_0% The Protector Cooldown Reduction",
            "X_0% The Seeker Cooldown Reduction",
            "X_0% Thrash Resource Generation",
            "X_0% Thunderspike Resource Generation",
            "X_0% Trample Cooldown Reduction",
            "X_0% Trap Cooldown Reduction",
            "X_0% Ultimate Cooldown Reduction",
            "X_0% Unstable Currents Cooldown Reduction",
            "X_0% Vigor Cost Reduction",
            "X_0% War Cry Cooldown Reduction",
            "X_0% Weapon Mastery Cooldown Reduction",
            "X_0% Withering Fist Resource Generation",
            "X_0% Wolves Cooldown Reduction",
            "X_0% Wrath of the Berserker Cooldown Reduction",
            "X_0% of incoming damage is instead suppressed. When you use a Defensive, Subterfuge or a Macabre skill, all suppressed damage is amplified by 250%[x] and explodes, dealing up to X_1 Fire damage to Nearby enemies.",
            "X_0% of the damage you would take is redirected across your Companions. Your Companions cannot die.",
            "You and your Minions deal X_0%[x] increased Shadow damage to enemies afflicted by any Curse.",
            "You and your Minions gain X_0% increased Damage Reduction.",
            "You are Blood Lanced, and when Blood Lance would deal damage to you, it instead Fortifies you for X_0% of your Maximum Life and has a 10% chance to form a Blood Orb.Blood Lance deals X_1%[x] increased damage.",
            "You are always Berserking",
            "You are permanently Cold Imbued.",
            "You are permanently Poison Imbued.",
            "You are permanently Shadow Imbued.",
            "You automatically activate the following equipped Skills on Corpses around you:Raise Skeleton every X_0 seconds.Corpse Explosion every X_0 seconds.Corpse Tendrils every X_2 seconds.",
            "You automatically drop a Smoke Grenade at the end of Dash. Smoke Grenades reduce Dash's Cooldown by X_0 seconds for each enemy hit, up to X_1 seconds.",
            "You become Immune for X_0 seconds after you lose more than 20% Life in a single hit. This effect can only occur once every 60 seconds.",
            "You cheated to become permanently blessed with the Artillery Shrine. Seems kind of unfair.",
            "You continually pulse Thorns damage every 5 seconds. The frequency increases up to 1s for 10 enemies.",
            "You deal 6%[x] increased Burning damage to enemies for each second they remain Burning, up to 30%[x] after 5 seconds. Additionally, you deal X_0%[x] increased Burning damage to enemies that are not Healthy.",
            "You deal X_0%[x] increased Critical Strike Damage to enemies marked by Inner Sight. While Inner Sight is full, you gain X_1%[x] increased damage.",
            "You deal X_0%[x] increased damage for 6 seconds after the Shadowblight Key Passive damages enemies 10 times.",
            "You deal X_0%[x] increased damage over time to enemies for each different Crowd Control effect they're afflicted by. Unstoppable enemies and Staggered Bosses take X_1%[x] increased damage over time from you instead.",
            "You deal X_0%[x] increased damage while Ice Armor is active. This amount is increased by another 0%[x] against Frozen enemies.",
            "You deal X_0%[x] increased damage while Unstoppable and for 5 seconds after. When you become Unstoppable, gain 50 of your Primary Resource.",
            "You deal X_0%[x] increased damage while hitting a Poisoned enemy as a Werebear or a Crowd Controlled enemy as a Werewolf.",
            "You deal X_0%[x] more damage to Immobilized, Stunned, or Frozen enemies.",
            "You deal x16% increased damage.",
            "You gain 10% more Cooldown Reduction.",
            "You gain 10% more Critical Strike Chance.",
            "You gain 10% more Life.",
            "You gain 10% movement speed.",
            "You gain 15%[+] Barrier Generation. You Heal X_0 Life per second while you have an active Barrier.",
            "You gain 20% Movement Speed. Lose this bonus for X_0 seconds after taking damage from a Close enemy.",
            "You gain Resolve for each X_0 Vigor you gain over 100.",
            "You gain X_0 Armor, but your Evade has 100% increased Cooldown.",
            "You gain X_0 Fury per second while Berserking.",
            "You gain X_0 Maximum Essence per active Minion.",
            "You gain X_0%[+] Critical Strike Damage but your Non-Critical Strikes deal X_1%[x] less damage.",
            "You gain X_0%[+] increased Armor for 4 seconds when you deal any form of damage, stacking up to X_1%[+].",
            "You gain X_0%[x] increased Lucky Hit Chance against Crowd Controlled enemies.",
            "You gain X_0%[x] increased Movement Speed when moving away from Slowed or Chilled enemies.",
            "You have 16% increased Critical Strike chance.",
            "You have 20%[+] increased Crowd Control Duration. While enemies are Unstoppable, you deal X_0%[x] increased damage to them.",
            "You have 8% increased Dodge Chance. Successful Dodges restore X_0% of your Maximum Life.",
            "You have 8% increased Dodge Chance. When you Dodge, Fortify for X_0% of your Maximum Life.",
            "You have X_0%[+] increased Critical Strike Chance against Injured enemies. While you are Healthy, you gain X_1%[+] increased Crowd Control Duration.",
            "You have a X_0% chance after taking direct damage to gain a Barrier equal to 20% of your Maximum Life for 10 seconds. This chance is doubled against Distant enemies.",
            "You have a X_0% chance to gain 1 Ferocity when you Crowd Control an enemy.",
            "You leave behind a trail of frost that Chills enemies. You deal X_0%[x] more damage to Chilled enemies.",
            "You may have 1 additional Hydra active, and Hydra's duration is increased by X_0%.",
            "You move 10% faster.",
            "You periodically call a bat companion that attacks an enemy and heals you.",
            "You take 16% reduced damage.",
            "You take 30% less damage when you are below half Life.You deal 50% more damage to enemies below half Life.",
            "You take X_0% less damage from Crowd Controlled enemies. Whenever a Crowd Controlled enemy deals direct damage to you, gain 15%[+] Movement Speed for 2 seconds.",
            "You take X_0% less damage from Crowd Controlled or Vulnerable enemies.",
            "Your Basic Skills are always Cold Imbued.You deal X_0%[x] increased damage to enemies who are Chilled or Frozen.",
            "Your Basic Skills deal X_0% increased damage and always use their 3rd attack. Every 3rd cast of a Basic Skill strikes three times.",
            "Your Blocked Damage Reduction is increased by X_0%[+] while you have a Barrier.",
            "Your Companions gain double the bonus from the Bestial Rampage Key Passive.",
            "Your Core Skills are now additionally Basic Skills and free to cast, but deal up to 30% reduced damage based on their Vigor Cost. When cast at Maximum Vigor, your Core Skills consume all Vigor to return to full damage, cast at their largest Size, and become guaranteed Critical Strikes, with X_0%[x] increased Critical Strike Damage for each point of Vigor spent this way.",
            "Your Core Skills deal X_0%[x] increased damage when spending 3 Combo Points. Your Basic Skills using this weapon have a 50% chance to generate 3 Combo Points.",
            "Your Core Skills deal up to X_0%[x]  increased damage based on your amount of Fortify.",
            "Your Critical Strike Chance is increased by X_0% of your Movement Speed bonus.Current Bonus: -X_1%",
            "Your Critical Strike Chance with Jaguar Skills is increased against enemies by X_0%[+] for each 10% of their missing Life.",
            "Your Darkness Skills Chill enemies for up to 100% and deal X_0%[x] increased damage to Frozen enemies.Lucky Hit: Your Darkness Skills have up to a 100% chance to generate X_1 additional Essence against Frozen targets.",
            "Your Defensive Skills Cooldown X_0% faster while you are moving.",
            "Your Earth Skills are now also Werebear Skills and Fortify you for X_0% of your Maximum Health.",
            "Your Evade is now Soar.Soar deals X_0%[x] increased damage.",
            "Your Golem Active also causes your Golem to erupt, dealing X_0 Physical damage to surrounding enemies. Your Golem consumes Corpses to reduce its remaining Cooldown by 1 second.",
            "Your Golem has a X_0% chance to reduce its active Cooldown by 2 seconds and a X_1% chance to spawn a Corpse each time it damages an enemy with its normal attack.",
            "Your Grenade Skills count as Trap Skills. Whenever you arm a Trap or drop Grenades, you gain X_0%[+] increased Movement Speed for 3 seconds.",
            "Your Healing Potion no longer heals instantly and instead grants a Barrier for 200% of the original healing for 4 seconds. You gain X_0% Damage Reduction while you have a Barrier and may drink your Healing Potion while at full Life.",
            "Your Imbuement Skill effects have X_0%[x] increased potency against Vulnerable enemies.",
            "Your Lucky Hit Chance is increased by X_0%[+] while you have a Barrier active.",
            "Your Maximum Vigor is increased by 50%, and killing an enemy grants you X_0 Vigor. While you have Ferocity, your Poisoning effects deal their damage across 33% of the normal duration.",
            "Your Minions deal 38 Shadow damage per second to enemies around them. The damage over time lasts for X_0 seconds after leaving the aura.",
            "Your Minions deal X_0%[x] increased damage, but each one drains 1 Essence from you per second. If you have no active Minions, the damage increase applies to you instead, and you lose 7 Essence per second.",
            "Your Minions gain increased damage while alive, up to X_0%[x] after 10 seconds.",
            "Your Minions' attacks have a X_0% chance to randomly inflict Decrepify or Iron Maiden.  You deal X_1%[x] increased Overpower damage to enemies affected by your Curses.",
            "Your Potion Also Grants +X_0% Maximum Resistances for 5 Seconds",
            "Your Rain of Arrows is always Imbued with all Imbuements and receives your Arrow Storm benefits.",
            "Your Reinforcement Cooldown is reduced by X_0%[x]. After casting your Ultimate Skill, call your Mercenary for Reinforcement. This cannot occur more than once each 30 seconds.",
            "Your Resolve has a X_0% chance to not be lost when you are hit for each stack of Ferocity you have.",
            "Your Rupture does not remove the Bleeding damage from enemies. Can only occur once every X_0 seconds.",
            "Your Sacrifice bonuses are increased by X_0%.",
            "Your Shadow Clones also mimic the Imbuements applied to your Skills. Casting an Imbuement Skill grants your active Shadow Clone X_0%[x] increased damage for 9 seconds.",
            "Your Shout Skills create 3 Dust Devils that deal X_0 damage to enemies along their path.Your Dust Devils are X_1% bigger and deal 1%[x] increased damage for each 1% their size is increased.",
            "Your Shout Skills generate X_0 Primary Resource per second while active.",
            "Your Skeletal Priests now also empower you at X_0% effectiveness.",
            "Your Skills gain X_0%[+] Critical Strike Chance per point of Primary Resource you have when cast, up to X_1%[+]. Each point of Primary Resource above 100 grants Skills 0.2%[x] Critical Strike Damage instead.",
            "Your Skills have a X_0% chance to Knock Down all enemies for 1.25 seconds when that Skill's Attack Speed is higher than X_1%.",
            "Your Subterfuge Skills create an attackable Shade Totem for X_0 seconds. Any base damage it takes is replicated onto surrounding enemies at 20% effectiveness. This effect is treated as a Trap Skill. You may only have 1 Shade Totem active at a time.",
            "Your Weapon Mastery Skills are now also Core Skills that have no Cooldowns but cost Fury, dealing X_0% of normal damage. Their Fury costs are reduced by 5 for any additional Charges the Skill would have had.",
            "Your Weapon Mastery Skills have an additional Charge.Lucky Hit: Damaging an enemy with a Weapon Mastery Skill has up to a X_0% chance to Stun them for 2 seconds.",
            "Your Wolf Companions are infused with the power of the storm, dealing Lightning damage and gaining the Storm Howl ability.",
            "Your Wolf Companions are now Werewolf Companions. Werewolf Companions deal X_0%[x] additional damage and can spread Rabies.",
            "Your attacks have a X_0% chance to apply all Imbuement effects at X_1%[x] of normal potency.",
            "Your attacks randomly deal 1% to X_0% of their normal damage.",
            "Your casts of Charged Bolts have a X_0% chance to be attracted to enemies and last 300% longer.",
            "Your damage over time effects are increased by X_0%[x] for each different Crowd Control effect on the target. Unstoppable monsters and Staggered bosses instead take X_1%[x] increased damage from your damage over time effects.",
            "Your maximum number of Skeletal Warriors and Skeletal Mages is increased by 2.",
            "Your mount can move through monsters and players.",
            "Your mount is immune to any crowd control effects.",
            "Your mount leaves a frost trail that chills enemies caught inside it."
    ]
;


