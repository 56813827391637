import React from "react";
import {Link} from "@aws-amplify/ui-react";

const getLink = function(name, link) {
    return <Link
        color="#F4D03F"
        href={link}
        isExternal={true}
    >
        {name}
    </Link>
}

export const genesis = function() {
    return getLink("Genesis", "https://www.wowhead.com/diablo-4/item/tuning-stone-genesis-1723635")
}

export const evernight = function() {
    return getLink("Evernight", "https://www.wowhead.com/diablo-4/item/tuning-stone-evernight-1723622")
}

export const uniquesLink = getLink("Uniques", "https://www.wowhead.com/diablo-4/guide/gear/target-farming#general-uniques");
export const runesLink = getLink("Runes", "https://www.wowhead.com/diablo-4/guide/gear/runes-runewords")
export const summoningMaterialsLink = getLink("Summoning Materials", "https://www.wowhead.com/diablo-4/items/summoning");
export const difficultyLink = getLink("Difficulty", "https://www.wowhead.com/diablo-4/news/new-difficulty-system-unveiled-diablo-4-patch-2-0-ptr-346346")

export const mythicsLink = getLink("Mythics", "https://www.wowhead.com/diablo-4/news/all-mythic-unique-updates-for-diablo-4-season-5-345623");
export const bossUniqueDrops = getLink("Uniques", "https://www.wowhead.com/diablo-4/guide/gear/target-farming#boss-drop-table-by-item");
export const zirUniques = getLink("Uniques", "https://www.wowhead.com/diablo-4/guide/gear/target-farming#dark-master-lord-zir");
export const varshanUniques = getLink("Uniques", "https://www.wowhead.com/diablo-4/guide/gear/target-farming#echo-of-varshan");
export const grigoireUniques = getLink("Uniques", "https://www.wowhead.com/diablo-4/guide/gear/target-farming#grigoire-the-galvanic-saint");
export const beastInIceUniques = getLink("Uniques", "https://www.wowhead.com/diablo-4/guide/gear/target-farming#the-beast-in-the-ice");

// specific uniques
export const fistsOfFate = getLink("Fists of Fate", "https://www.wowhead.com/diablo-4/item/fists-of-fate-186283");
export const flickerStep = getLink("Flickerstep", "https://www.wowhead.com/diablo-4/item/flickerstep-291669");
export const rakanothsWake = getLink("Rakanoth's Wake", "https://www.wowhead.com/diablo-4/item/rakanoths-wake-1944504");
export const theOculus = getLink("The Oculus", "https://www.wowhead.com/diablo-4/item/the-oculus-1439530")