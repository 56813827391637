export const COLOR_SUCCESS = "#365e3d";
export const COLOR_ERROR = "#660000";

export const COLOR_OCHRE = "#dca779";

export const COLOR_ORANGE = "#ff8000";
export const COLOR_YELLOW = "#e5e848";
export const COLOR_WHITE = "#ffffff";
export const COLOR_BLUE = "rgb(118, 134, 179)";
export const COLOR_GRAY = "#aaa";
export const COLOR_TEAL = "#7dd6e8";
export const COLOR_DARK_TEAL = "#047d95";

export const COLOR_GREAT_AFFIX = "#EBD1BA";
export const COLOR_UNIQUE = COLOR_OCHRE
export const COLOR_LEGENDARY = COLOR_ORANGE
export const COLOR_RARE = COLOR_YELLOW
export const COLOR_MAGIC = COLOR_BLUE
export const COLOR_NORMAL = COLOR_WHITE
export const COLOR_SUMMONING = "#56bef0";
export const COLOR_RUNE = "#ebb482";